import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule as AngularFormModule } from '@angular/forms';
import { SEARCH_STATES } from '@monsido/pages/backend-admin/support/search/search.state';
import { SearchPageComponent } from '@monsido/pages/backend-admin/support/search/search.component';
import { SearchService } from '@monsido/pages/backend-admin/support/search/search.service';
import { UIRouterModule } from '@uirouter/angular';
import {
    FormBuilderInputModule,
    LayoutModule,
    IconsModule,
    FormBuilderValidationModule,
} from '@monsido/angular-shared-components';
import { TranslateModule } from 'app/modules/translate.module';

@NgModule({
    imports: [
        AngularFormModule,
        CommonModule,
        TranslateModule,
        FormBuilderInputModule,
        FormBuilderValidationModule,
        IconsModule,
        LayoutModule,
        UIRouterModule.forChild({ states: SEARCH_STATES }),
    ],
    providers: [SearchService],
    declarations: [SearchPageComponent],
})
export class SearchModule {}
