import { Injectable } from '@angular/core';
import { BackendDataPrivacyCheckRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-data-privacy-check.repo';
import { DATA_PRIVACY_CHECK } from '@monsido/core/constants/data-privacy-check.constant';
import { ToastService, DialogService } from '@monsido/angular-shared-components';
import { DataPrivacyCheckInterface } from '@monsido/modules/models/api/interfaces/data-privacy-check.interface';
import { FormBackendAdminDataPrivacyCheckComponent } from '@monsido/forms/backend_admin/data-privacy-check/data-privacy-check.component';
import { SearchService } from '@monsido/pages/backend-admin/data-privacy-checks/helper/search.service';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from 'app/services/translate/translate.service';

@Injectable()
export class DataPrivacyChecksService {
    constructor (
        private backendDataPrivacyCheckRepo: BackendDataPrivacyCheckRepo,
        private toastService: ToastService,
        private translateService: TranslateService,
        private dialogService: DialogService,
        private searchService: SearchService,
    ) {}

    getFilteredChecks (checks: DataPrivacyCheckInterface[], search: string): DataPrivacyCheckInterface[] {
        if (!search) {
            return checks;
        }
        return checks.filter((check) => this.searchService.fuzzy(search, check.name));
    }

    getAll (params: HttpParams): Promise<DataPrivacyCheckInterface[]> {
        return this.backendDataPrivacyCheckRepo.getAll(params);
    }

    updateApprovalStatus (check: DataPrivacyCheckInterface): Promise<void> {
        if (!check.how_to_mitigate) {
            check.how_to_mitigate = 'update';
        }
        check.approved = !check.approved;
        return this.backendDataPrivacyCheckRepo.updateCheck(check).then(
            () => {
                this.toastService.success(this.translateService.getString('Check\'s approval status updated'));
            },
            () => {},
        );
    }

    getPriorityLevel (check: DataPrivacyCheckInterface): string {
        // TODO remove any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const levels: Array<Record<string, string | any>> = this.getLevels();
        const index: number = this.getIndex(levels, check.priority);
        if (index === -1) {
            return this.translateService.getString('No Priority level set');
        } else {
            return this.translateService.getString(levels[index].name);
        }
    }

    getGroupName (group: string): string {
        const groups: Array<Record<string, string>> = this.getGroups();
        for (const groupData of groups) {
            if (groupData.value === group) {
                return groupData.name;
            }
        }
    }

    getRegionNames (region: string[]): string {
        const regions: Array<Record<string, string>> = this.getRegions();
        const names: string[] = [];
        for (let i = 0; i < region.length; i++) {
            for (let x = 0; x < regions.length; x++) {
                if (region[i] === regions[x].value) {
                    names.push(regions[x].name);
                }
            }
        }

        return names.join(', ');
    }

    getRegions (): Array<Record<string, string>> {
        return DATA_PRIVACY_CHECK.REGIONS.slice();
    }

    getGroups (): Array<Record<string, string>> {
        return DATA_PRIVACY_CHECK.GROUPS.slice();
    }

    getLevels (): Array<Record<string, string | number>> {
        return DATA_PRIVACY_CHECK.PRIORITY_LEVELS.slice();
    }

    openDialog (check: DataPrivacyCheckInterface, cb: (...args: unknown[]) => void): void {
        const dialogRef = this.dialogService.open(FormBackendAdminDataPrivacyCheckComponent, {
            defaultWrapper: false,
            classes: 'mon-dialog-size-md',
            formConfirmPromptParentSelector: '#common-dialog-wrapper',
            cb,
        });

        dialogRef.componentInstance.check = Object.assign({}, check);
    }

    protected getIndex (collection: { [key: string]: unknown }[], value: number, parameter: string = 'value'): number {
        return collection.map((level) => level[parameter]).indexOf(value);
    }
}
