import { Component, OnInit, Type } from '@angular/core';
import { DialogOptions, DialogService } from '@monsido/angular-shared-components';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';

export interface DialogOptionsAdapter<T> {
    component: Type<T>,
    dialogOptions?: DialogOptions,
    beforeCloseCb?: () => Promise<boolean>,
    data?: Record<string, unknown>
}

@Component({
    selector: 'mon-dialog-container',
    template: ``,
})
export class DialogContainerComponent implements OnInit {
    constructor (
        private dialogService: DialogService,
        private eventService: MonEventService,
    ) {}

    ngOnInit (): void {
        this.eventService.addListener(MON_EVENTS.CLOSE_ALL_OVERLAY_DIALOGS, (index: number) => {
            this.dialogService.closeAll();
            return index;
        });

        this.eventService.addListener(MON_EVENTS.LOAD_NG2_DIALOG, (dialogOptionsAdapter: DialogOptionsAdapter<unknown>) => {
            const { component, dialogOptions = {}, beforeCloseCb, data = {} } = dialogOptionsAdapter;

            const dialogOptionsCopy: DialogOptions = {
                ...dialogOptions,
                manualFocus: true,
                parentSelector: '#common-dialog-wrapper',
                formConfirmPromptParentSelector: '#common-dialog-wrapper',
            };

            const dialogRef = this.dialogService.open(component, dialogOptionsCopy);

            if (dialogRef) {
                if (beforeCloseCb) {
                    dialogRef.setBeforeClosedCallback(beforeCloseCb);
                }
                for (const [key, value] of Object.entries(data)) {
                    if (dialogRef.componentInstance && typeof dialogRef.componentInstance === 'object') {
                        (dialogRef.componentInstance as Record<string, unknown>)[key] = value;
                    }
                }
            }

            return dialogRef as unknown as number;
        });
    }
}
