import { Component, OnInit } from '@angular/core';
import { UrlService } from '@uirouter/core';
import { Customer } from '@monsido/modules/models/api/customer';
import { environment } from '../../../../../environments/environment';
import { ParamService } from '@monsido/core/param/param.service';
import { User } from '@monsido/modules/models/api/user';
import { SearchUsersService } from '@monsido/pages/backend-admin/support/search-users/search-users.service';

interface Env {
    name: string;
    url: string;
    users: User[];
}

@Component({
    selector: 'mon-search-users-page',
    templateUrl: 'search-users.html',
})
export class SearchUsersPageComponent implements OnInit {
    envs: Array<Env> = [];
    user: Customer | void;
    searching: boolean = false;
    search_string: string;
    loading: boolean = false;
    constructor (private $location: UrlService, private searchService: SearchUsersService, private paramService: ParamService) {}

    async ngOnInit (): Promise<void> {
        this.search_string = this.$location.search()['search'];

        if (this.search_string) {
            await this.search();
        }
    }

    canSearch (): string {
        return this.search_string;
    }

    async goToEnv (env: Env, user: User): Promise<void> {
        await this.searchService.redirectToEnv(user, env.url, env.name);
    }

    async search (): Promise<void> {
        let env: { name: string; url: string };
        this.user = null;
        this.envs = [];
        let singleEnv: Env;
        this.searching = true;

        this.paramService.setParams({
            search: this.search_string,
        });

        for (let i = 0; i < environment.apiEnvs.length; i++) {
            env = environment.apiEnvs[i];
            singleEnv = {
                name: env.name,
                url: env.url,
                users: [],
            };

            const users = await this.searchService.searchUsers(this.search_string, env.url).catch(() => {});
            if (users) {
                singleEnv.users = users;
            }
            this.envs.push(singleEnv);
        }
        this.searching = false;
    }
}
