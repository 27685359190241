import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { EmailValidatorService } from '@monsido/modules/utils/email-validator.service';
import { LANGUAGE_CONSTANT } from '@monsido/core/constants/languages.constant';
import { FormBuilderCheckboxModule, FormBuilderInputModule,
    FormBuilderSelectModule, FormBuilderValidationModule,
    MonSwitchModule,
} from '@monsido/angular-shared-components';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { MonEscapeRegExpPipe } from '../../../../filters/mon-escape-reg-exp.pipe';
import { BackendAdminUserForm } from '@monsido/forms/backend_admin/customer-user/user-form';
import { TranslateModule } from 'app/modules/translate.module';

@Component({
    selector: 'mon-form-customer-user-basic',
    templateUrl: './form-customer-user-basic.component.html',
    standalone: true,
    imports: [
        FormBuilderInputModule,
        CommonModule,
        FormsModule,
        FormBuilderInputModule,
        FormBuilderCheckboxModule,
        FormBuilderValidationModule,
        FormBuilderSelectModule,
        TranslateModule,
        MonEscapeRegExpPipe,
        MonSwitchModule,
    ],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormCustomerUserBasicComponent implements OnInit {
    @Input() user: BackendAdminUserForm;
    @Input() modelGroup = '';
    @Output() isFormValid: EventEmitter<boolean> = new EventEmitter(false);

    emailPattern = '';
    supportedLanguages = [LANGUAGE_CONSTANT.en, LANGUAGE_CONSTANT.da];
    isAdmin = false;

    constructor (
        private sessionService: SessionService,
        private emailValidatorService: EmailValidatorService,
    ) {}

    async ngOnInit (): Promise<void> {
        this.emailPattern = this.emailValidatorService.getEmailPattern();
        this.user.locale = this.user.locale || LANGUAGE_CONSTANT.en.code;
        this.user.send_welcome_email = true;

        await this.sessionService.getUser();
        this.isAdmin = this.sessionService.isBackendAdmin();
    }
}
