import { BackendAdminApiClient } from '../backend-admin-api-client';
import { Injectable } from '@angular/core';
import { PlainHttpParams } from '@monsido/http/params';
import { User } from '@monsido/modules/models/api/user';
import { HttpHeaders } from '@angular/common/http';
import { createDefaultHttpParams } from '@monsido/modules/endpoints/api/support-api-client';

@Injectable()
export class BackendUserRepo {
    constructor (private adminClient: BackendAdminApiClient) {}

    getAll (params?: PlainHttpParams, headers?: HttpHeaders, urlPrefix?: string): Promise<User[]> {
        return this.adminClient.getPromise(
            'users',
            createDefaultHttpParams(params, headers),
            urlPrefix,
        );
    }

    sudoUser (userId: number, customerId: number): Promise<void> {
        return this.adminClient.postPromise(`users/${userId}/sudo`, {}).then((data) => {
            window.open(`${data.uri}&customer_id=${customerId}`, '_blank');
        });
    }
}
