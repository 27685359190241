<div class="mon-dialog-header">
    <mon-page-header
        header="{{'Details for crawl' | translate }}"
        subHeader="{{crawl.domain.id}} - {{crawl.domain.title}}"
    ></mon-page-header>
</div>

<div class="mon-dialog-body">
    <div class="px-3 pt-3 font-size-1-1">
        <div class="mb-3">
            <mon-table-dropdown title="{{'Release' | translate }}" position="pull-left" class="mr-1">
                <li role="menuitem">
                    <a monConfirm="{{ 'Confirm release?' | translate }}"
                       (monConfirmAction)="releaseCrawl('ok')"
                       class="cursor-pointer" href>
                        <mon-icon class="mr-1" [icon]="['ACTIONS', 'APPROVE']"></mon-icon>
                        <span translate>Ok</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a monConfirm="{{ 'Confirm release due to url-rewrite?' | translate }}"
                       (monConfirmAction)="releaseCrawl('url-rewrite')"
                       class="cursor-pointer" href>
                        <mon-icon [icon]="['ACTIONS', 'REDO']"></mon-icon>
                        <span class="pl-1" translate>Url-rewrite</span>
                    </a>
                </li>
            </mon-table-dropdown>

            <mon-table-dropdown title="{{'Reject' | translate }}" position="pull-left" class="mr-1">
                <li role="menuitem">
                    <a monConfirm="{{ 'Confirm reject due to being stuck in support?' | translate }}"
                       (monConfirmAction)="rejectCrawl('stuck-in-support')"
                       class="cursor-pointer" href>
                        <mon-icon [icon]="['ACTIONS', 'STUCK']"></mon-icon>
                        <span class="pl-1" translate>Stuck in support</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a monConfirm="{{ 'Confirm reject for other reason?' | translate }}"
                       (monConfirmAction)="rejectCrawl('other')"
                       class="cursor-pointer" href>
                        <mon-icon [icon]="['ACTIONS', 'EDIT']"></mon-icon>
                        <span class="pl-1" translate>Other</span>
                    </a>
                </li>
            </mon-table-dropdown>

            <mon-table-dropdown title="{{'Reject & Rescan' | translate }}" position="pull-left" class="mr-1">
                <li role="menuitem">
                    <a monConfirm="{{ 'Confirm reject and do a new rescan?' | translate }}"
                       (monConfirmAction)="rejectRescanCrawl('rescan')"
                       class="cursor-pointer" href>
                        <mon-icon [icon]="['SPINNER', 'SYNC']"></mon-icon>
                        <span class="pl-1" translate>Rescan</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a monConfirm="{{ 'Confirm reject due to url-rewrite?' | translate }}"
                       (monConfirmAction)="rejectRescanCrawl('url-rewrite')"
                       class="cursor-pointer" href>
                        <mon-icon [icon]="['ACTIONS', 'STUCK']"></mon-icon>
                        <span class="pl-1" translate>Url-rewrite</span>
                    </a>
                </li>
            </mon-table-dropdown>

            <button class="btn btn-secondary mr-1"
                    *ngIf="crawl.state == 'ON-HOLD'"
                    monConfirm="{{'Mark crawl for support?' | translate}}"
                    (monConfirmAction)="markCrawlForSupport()"
                    type="button"
                    translate>
                Mark for support
            </button>
            <button *ngIf="crawl.state === 'STARTED' || crawl.state === 'QUEUED' || crawl.state === 'BACKOFF'"
                    monConfirm="{{'Confirm stop crawl?' | translate}}"
                    (monConfirmAction)="stopCrawl()"
                    class="btn btn-secondary"
                    translate>
                Stop
            </button>
        </div>
        <mon-spinner [loading]="loading">
            <div class="card monsido-panel mt-3" *ngIf="crawl.state == 'SUPPORT'">
                <div class="card-body">
                    <mon-panel-header pHeader="{{'Crawl marked for support' | translate}}">
                    </mon-panel-header>
                    <div class="card-body py-3 pl-4 font-size-1-1">
                        <div [hidden]="crawldetails.support_link" >
                            <a [href]="crawldetails.support_link" target="_blank" aria-describedby="linkOpensInNewTab">
                                <mon-icon [icon]="['LINKS', 'EXTERNAL']"></mon-icon>
                                {{ crawldetails.support_link }}
                            </a>
                        </div>
                        <span *ngIf="!crawldetails.support_link" translate>Add URL underneath</span>
                        <form name="form" class="form-horizontal mt-3" (ngSubmit)="saveSupportLink()">
                            <ng-form name="crawlSupportLink">
                                <mon-form-field-input [(ngModel)]="crawl.support_link"
                                                      monLabel="{{ 'Support link: ' | translate }}"
                                                      monPlaceholder="{{ 'URL to support issue' | translate }}"
                                                      [type]="'text'"
                                                      [name]="'support_link'"
                                                      data-test="support-link-input">
                                </mon-form-field-input>
                            </ng-form>
                            <div class="text-right">
                                <button class="btn btn-secondary" [disabled]="onSave" type="submit" data-test="save-button">
                                    <span *ngIf="!onSave" translate>Save</span>
                                    <mon-icon *ngIf="onSave" [icon]="['SPINNER', 'ICON']" [spin]="true"></mon-icon>
                                </button>
                            </div>
                        </form>
                    </div>
                    </div>
                </div>
            <div class="card monsido-panel mt-3">
                <div class="card-body">
                    <mon-panel-header pHeader="{{'Crawl detail' | translate}}">
                    </mon-panel-header>
                    <div class="card-body" *ngIf="domain">
                        <div class="font-size-1-1">
                            <div class="row">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                                    <span translate>Reason</span>:
                                </div>
                                <div class="col-sm-26 col-lg-36">
                                    {{crawl.on_hold_info?.reason || ' '}}
                                </div>
                            </div>
                            @if (showPercentageSection) {
                                <div class="row">
                                    <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                                        <span translate>Percentage</span>:
                                    </div>
                                    <div class="col-sm-26 col-lg-36">
                                        {{crawl.on_hold_info?.percentage || 0 | number}}%
                                    </div>
                                </div>
                            }
                            <div class="row" *ngIf="crawl.on_hold_info?.prediction">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                                    <span translate>Prediction</span>:
                                </div>
                                <div class="col-sm-26 col-lg-36">
                                    {{crawl.on_hold_info?.prediction}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                                    <span translate>Pages(current, new)</span>:
                                </div>
                                <div class="col-sm-26 col-lg-36">
                                    {{ domain.crawl_history.page_count + domain.crawl_history.documents_count || 0 | number }}
                                    -&gt;
                                    {{ crawl.on_hold_info?.page_count || 0 | number }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                                    <span translate>Links (current, new)</span>:
                                </div>
                                <div class="col-sm-26 col-lg-36">
                                    {{domain.crawl_history.links_count || 0 | number}}
                                    -&gt;
                                    {{crawl.on_hold_info?.link_count || 0 | number}}
                                </div>
                            </div>
                            <div class="row" *ngIf="domain.features.accessibility">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                                    <span translate>Accessibility errors (current, new)</span>:
                                </div>
                                <div class="col-sm-26 col-lg-36">
                                    {{domain.crawl_history.accessiblity_issues_count || 0 | number}}
                                    -&gt;
                                    {{crawl.on_hold_info?.acc_count || 0 | number}}
                                </div>
                            </div>
                            @if (showCSVWithMissingPagesSection && crawl.on_hold_info?.removed_pages_csv) {
                                <div class="row">
                                    <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                                        <span translate>CSV with "missing" pages</span>:
                                    </div>
                                    <div class="col-sm-26 col-lg-36">
                                        <a [href]="crawl.on_hold_info?.removed_pages_csv" target="_blank" aria-describedby="linkOpensInNewTab">
                                            {{crawl.on_hold_info?.removed_pages_csv}}
                                        </a>
                                    </div>
                                </div>
                            }
                            @let pageWithAccFailureCount = crawl.on_hold_info.pages_with_acc_failure_count;
                            @let pageWithAccFailureCsv = crawl.on_hold_info.pages_with_acc_failure_csv;
                            @if (pageWithAccFailureCount) {
                                <div class="row">
                                    <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                                        <span translate>Pages with accessibility failure count</span>:
                                    </div>
                                    <div class="col-sm-26 col-lg-36">
                                        {{ pageWithAccFailureCount | number }}
                                    </div>
                                </div>
                            }
                            @if (pageWithAccFailureCsv) {
                                <div class="row">
                                    <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                                        <span translate>CSV with pages which contain accessibility failure</span>:
                                    </div>
                                    <div class="col-sm-26 col-lg-36">
                                        <a [href]="pageWithAccFailureCsv" target="_blank" aria-describedby="linkOpensInNewTab">
                                            {{ pageWithAccFailureCsv }}
                                        </a>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <mon-panel-header pHeader="{{'Domain' | translate}}">
            </mon-panel-header>
            <div class="card monsido-panel mt-3">
                <div class="card-body">
                    <mon-panel-header pHeader="{{'Domain notes' | translate}}">
                    </mon-panel-header>
                    <div class="card-body" *ngIf="domain">
                        <form name="form" class="form-horizontal" (ngSubmit)="saveComment()">
                            <ng-form name="crawlComment">
                                <mon-form-field-textarea name="comment"
                                                         [(model)]="domain.notes"
                                                         label="{{ 'Notes' | translate }}"
                                                         rows="10"
                                                         placeholder="{{ 'Notes' | translate }}"
                                                         type="text"
                                                         data-test="comment-textarea">
                                </mon-form-field-textarea>
                            </ng-form>
                            <div class="text-right">
                                <button class="btn btn-secondary" [disabled]="onSave" type="submit" data-test="save-button">
                                    <span *ngIf="!onSave" translate>Save</span>
                                    <mon-icon *ngIf="onSave" [icon]="['SPINNER', 'ICON']" [spin]="true"></mon-icon>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="card monsido-panel mt-3">
                <div class="card-body">
                    <mon-panel-header pHeader="{{'Base info' | translate}}">
                    </mon-panel-header>

                    <div class="card-body">
                        <div class="font-size-1-1" *ngIf="domain">
                            <div class="row">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500" translate>
                                    Url (domain id)
                                </div>
                                <div class="col-sm-26 col-lg-36 text-grey">
                                    <a [href]="domain.url" target="_blank" aria-describedby="linkOpensInNewTab">{{ domain.url }} - ({{domain.id}})</a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500" translate>
                                    Customer (id)
                                </div>
                                <div class="col-sm-26 col-lg-36 text-grey">
                                    <a uiSref="base.backend_admin.customers.show"
                                       [uiParams]="{customer_id: domain.customer.id}">
                                       {{ domain.customer.name }} - ({{ domain.customer.id }})
                                    </a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500" translate>
                                    CMS
                                </div>
                                <div class="col-sm-26 col-lg-36 text-grey">
                                    {{ domain.cms | monUnderscoreless }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500" translate>
                                    Active status
                                </div>
                                <div class="col-sm-26 col-lg-36 text-grey">
                                    {{ domain.active ? 'Active' : 'Inactive' | translate }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                                    <span translate>Notes</span>:
                                </div>
                                <div class="col-sm-26 col-lg-36 text-grey">
                                    <span *ngIf="domain.notes">{{ domain.notes }}</span>
                                    <span *ngIf="!domain.notes" translate>No notes</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card monsido-panel mt-3">
                <div class="card-body">
                    <mon-panel-header pHeader="{{'Plan traits' | translate}}">
                    </mon-panel-header>

                    <div class="card-body" *ngIf="domain">
                        <div class="font-size-1-1" *ngFor="let item of domain.customer.plan_traits | keyvalue">
                            <div class="row" *ngIf="hideFeatures.indexOf(item.key) < 0">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500 text-capitalize">
                                    {{ item.key | monUnderscoreless }}
                                </div>
                                <div class="col-sm-26 col-lg-36 text-grey">
                                    <span *ngIf="!(item.value | monIsBoolean)">{{ item.value }}</span>
                                    <mon-icon *ngIf="item.value && (item.value | monIsBoolean)" [icon]="['CUSTOMERS', 'FEATURES', 'ACTIVE']"></mon-icon>
                                    <mon-icon *ngIf="!item.value && (item.value | monIsBoolean)" [icon]="['CUSTOMERS', 'FEATURES', 'INACTIVE']"></mon-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="card monsido-panel mt-3">
                    <div class="card-body">
                    <mon-panel-header pHeader="{{'Features' | translate}}">
                    </mon-panel-header>

                    <div class="card-body">
                        <div class="font-size-1-1" *ngIf="domain">
                            <div class="row" *ngFor="let item of domain.features | keyvalue">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500 text-capitalize">
                                    {{ item.key | monUnderscoreless }}
                                </div>
                                <div class="col-sm-26 col-lg-36 text-grey">
                                    <span *ngIf="!(item.value | monIsBoolean)">{{ item.value }}</span>
                                    <mon-icon *ngIf="item.value && (item.value | monIsBoolean)" [icon]="['CUSTOMERS', 'PLAN_TRAITS', 'ACTIVE']"></mon-icon>
                                    <mon-icon *ngIf="!item.value && (item.value | monIsBoolean)" [icon]="['CUSTOMERS', 'PLAN_TRAITS', 'INACTIVE']"></mon-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
           <div class="card monsido-panel mt-3">
                 <div class="card-body">
                    <mon-panel-header pHeader="{{'Scan' | translate}}">
                    </mon-panel-header>

                    <div class="card-body">
                        <div class="font-size-1-1" *ngIf="domain">
                            <div class="row" *ngFor="let item of domain.scan | keyvalue">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500 text-capitalize">
                                    {{ item.key | monUnderscoreless }}
                                </div>
                                <div class="col-sm-26 col-lg-36 text-grey">
                                    <span *ngIf="!(item.value | monIsBoolean)">{{ item.value }}</span>
                                    <mon-icon *ngIf="item.value && (item.value | monIsBoolean)" [icon]="['CUSTOMERS', 'SCAN', 'ACTIVE']"></mon-icon>
                                    <mon-icon *ngIf="!item.value && (item.value | monIsBoolean)" [icon]="['CUSTOMERS', 'SCAN', 'INACTIVE']"></mon-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
           <div class="card monsido-panel mt-3">
            <div class="card-body">
                    <mon-panel-header pHeader="{{'Tech info' | translate}}">
                    </mon-panel-header>

                    <div class="card-body">
                        <div class="font-size-1-1" *ngIf="domain">
                            <div class="row">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                                    <span translate>path constraints</span>:
                                </div>
                                <div class="col-sm-26 col-lg-36 text-grey">
                                    <div *ngFor="let constraint of domain.path_constraints" class="mr-1">
                                        {{ constraint.constraint }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-22 col-lg-12 text-right text-black font-weight-500">
                                    <span translate>Link excludes</span>:
                                </div>
                                <div class="col-sm-26 col-lg-36 text-grey">
                                <span *ngFor="let exclude of domain.link_excludes">
                                    {{ exclude.regex }}
                                    <br>
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </mon-spinner>
    </div>
</div>
