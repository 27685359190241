import { NgModule } from '@angular/core';
import { BaseLayoutModule } from '@layout/layout.module';
import { UIRouterModule } from '@uirouter/angular';
import { CRAWLERS_STATES } from './crawler.state';
import { BackendAdminCrawlersComponent } from './crawler.component';
import { BackendAdminCrawlersService } from './crawler.service';
import { CommonModule } from '@angular/common';
import { TableModule, LayoutModule, IconsModule } from '@monsido/angular-shared-components';
import { TranslateModule } from 'app/modules/translate.module';

@NgModule({
    providers: [BackendAdminCrawlersService],
    imports: [
        BaseLayoutModule,
        UIRouterModule.forChild({ states: CRAWLERS_STATES }),
        LayoutModule,
        CommonModule,
        TableModule,
        IconsModule,
        TranslateModule,
    ],
    declarations: [BackendAdminCrawlersComponent],
})
export class BackendAdminCrawlersModule {}
