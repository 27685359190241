<mon-page-header [monIcon]="['SUPPORT', 'HEATMAPS', 'ICON']" header="{{'Support Heatmaps' | translate}}">
    <div class="text-right">
    </div>
</mon-page-header>

<form name="form" class="form-horizontal" #form="ngForm" (ngSubmit)="form.valid && search()">
    <div class="row">
        <div class="col-48 col-md-14 pr-0">
            <mon-form-field-input [(ngModel)]="domain_id"
                                  monLabel="{{ 'Domain id' | translate }}"
                                  monPlaceholder="{{ 'Domain id' | translate }}"
                                  [type]="'text'"
                                  #domainId="ngModel"
                                  (ngModelChange)="onDomainIdUpdate()"
                                  [name]="'domainId'"
                                  data-test="domain-id-input"
                                  required>
            </mon-form-field-input>
            <mon-form-field-errors
                [showError]="domainId.control.touched && domainId.control.invalid"
                [errors]="domainId.control.errors">
            </mon-form-field-errors>
        </div>
        <div class="col-48 col-md-16 pr-0">
            <mon-form-field-input [(ngModel)]="url"
                                  monLabel="{{ 'URL' | translate }}"
                                  monPlaceholder="{{ 'URL' | translate }}"
                                  [type]="'text'"
                                  [name]="'name'"
                                  data-test="url-input"
            >
            </mon-form-field-input>
        </div>
        <div class="col-48 col-md-12 pr-0">
            <mon-date-picker-custom [monEnableComparisonRange]="false" [dateFrom]="date.startDate" [dateTo]="date.endDate" data-test="date-picker"></mon-date-picker-custom>
        </div>
        <div class="col-48 col-md-6 pr-0">
            <button class="btn btn-secondary pull-right mr-3" [disabled]="searching || (form.invalid && !canSearch()) || this.domains.length === 0" type="submit" data-test="search-button">
                <span [hidden]="searching" translate>Search</span>
                <mon-icon [hidden]="!searching"
                      [spin]="searching"
                      [icon]="['SPINNER', 'ICON']">
                </mon-icon>
            </button>
        </div>
    </div>
</form>

<div class="row mt-5 ml-2">
    <div class="col-48">
        <span translate>Domain</span>
        <ng-container *ngIf="domain_id && domains.length > 0 && !loadingDomains">
            {{ domains[0].url }}
        </ng-container>
        <ng-container *ngIf="domain_id && domains.length === 0 && !loadingDomains">
            {{ 'No domain found' | translate }}
        </ng-container>
    </div>
</div>

<div class="card monsido-panel mt-5">
    <div class="card-body">
        <mon-table-container [collection]="heatmaps"
                             [loading]="loading"
                             class="table-vertial-align">
            <div class="table-container-body">
                <table class="table table-fixed table-hover">
                    <thead>
                    <tr>
                        <th translate>URL</th>
                        <th translate class="text-right">User count</th>
                        <th translate class="text-right">Clicks</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let heatmap of heatmaps">
                        <td>{{ heatmap.url }}</td>
                        <td class="text-right">
                            {{ heatmap.user_count }}
                        </td>
                        <td class="text-right">
                            {{ heatmap.clicks}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </mon-table-container>
    </div>
</div>
