import { Component, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ToastService, ActiveDialog } from '@monsido/angular-shared-components';
import { PAGE_FIX_DOMAIN_SETTINGS_MODULE } from '@monsido/core/constants/page-fix-domain-settings.constant';
import { BackendDomainsRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-domains.repo';
import { Customer } from '@monsido/modules/models/api/customer';
import { Domain } from '@monsido/modules/models/api/domain';
import { DomainDataType, PageCorrect } from 'types/domain';
import { Checks, Settings } from '@monsido/modules/models/api/interfaces/page-fix-setting.interface';
import * as Lodash from 'lodash';
import { CustomerRepo } from '@monsido/modules/endpoints/api/admin/customer.repo';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-page-fix-domain-settings',
    templateUrl: 'page-fix-domain-settings.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class PageFixDomainSettingsComponent implements OnInit, AfterViewInit {
    @Input() domain: Domain = new Domain();
    @Input() customer: Customer;
    @Input() saveToAPI: boolean;
    @Input() isAdmin = false;
    @ViewChild('pageCorrectForm', { static: false }) pageCorrectForm: NgForm;

    form: NgForm;
    settings: Settings;
    saving: boolean = false;
    isValid: boolean = true;

    constructor (
        private activeDialog: ActiveDialog,
        private backendDomainsRepo: BackendDomainsRepo,
        private toastService: ToastService,
        private translateService: TranslateService,
        private customerRepo: CustomerRepo,
    ) {}

    ngOnInit (): void {
        this.settings = {
            admin_only: !!this.domain.settings.scripts.page_correct.admin_only,
            checks: {
                spelling: {
                    name: 'SPELLING_ERROR',
                    value: Lodash.some(this.domain.settings.scripts.page_correct.enabled_checks, (check) => {
                        return PAGE_FIX_DOMAIN_SETTINGS_MODULE.SPELLING_ERROR === check;
                    }),
                },
                accessibility_source_code: {
                    name: 'ACCESSIBILITY_SOURCE_CODE',
                    value: Lodash.some(this.domain.settings.scripts.page_correct.enabled_checks, (check) => {
                        return PAGE_FIX_DOMAIN_SETTINGS_MODULE.ACCESSIBILITY_SOURCE_CODE === check;
                    }),
                },
                accessibility_check: {
                    name: 'ACCESSIBILITY_CHECK',
                    value: Lodash.some(this.domain.settings.scripts.page_correct.enabled_checks, (check) => {
                        return PAGE_FIX_DOMAIN_SETTINGS_MODULE.ACCESSIBILITY_CHECK === check;
                    }),
                },
                link: {
                    name: 'LINK',
                    value: Lodash.some(this.domain.settings.scripts.page_correct.enabled_checks, (check) => {
                        return PAGE_FIX_DOMAIN_SETTINGS_MODULE.LINK === check;
                    }),
                },
                custom: {
                    name: 'CUSTOM',
                    value: Lodash.some(this.domain.settings.scripts.page_correct.enabled_checks, (check) => {
                        return PAGE_FIX_DOMAIN_SETTINGS_MODULE.CUSTOM === check;
                    }),
                },
            },
        };
    }

    ngAfterViewInit (): void {
        if (this.pageCorrectForm) {
            this.activeDialog.setForm(this.pageCorrectForm);
        }
    }

    async submit (): Promise<void> {
        this.saving = true;

        const checks: Checks[] | string[] = Object.keys(this.settings.checks)
            .map((checkKey: string) => {
                const check = this.settings.checks[checkKey as keyof Checks];
                if (check.value) {
                    return PAGE_FIX_DOMAIN_SETTINGS_MODULE[check.name];
                }
            })
            .filter(Boolean);
        const pageCorrectSettings: PageCorrect = {
            admin_only: this.settings.admin_only,
            enabled_checks: checks,
        };
        if (this.saveToAPI) {
            try {
                const domain = await this.updateApiFn(pageCorrectSettings);
                this.toastService.success(this.translateService.getString('PageCorrect Settings Updated'));
                const res: PageCorrect = Lodash.merge({}, domain.settings.scripts.page_correct);
                res.admin_only = this.settings.admin_only;
                res.enabled_checks = checks as string[];
                this.pageCorrectForm.form.markAsPristine();
                this.close(res);
            } finally {
                this.saving = false;
            }
        } else {
            this.close(pageCorrectSettings);
        }
    }

    updateValid (): void {
        setTimeout(() => {
            this.isValid = Object.keys(this.settings.checks).some((checkKey: keyof Checks) => {
                const check = this.settings.checks[checkKey];
                return check.value;
            });
        });
    }

    close (settings: PageCorrect): void {
        this.pageCorrectForm.form.markAsPristine();
        this.activeDialog.close(settings);
    }

    private async updateApiFn (pageCorrectSettings: PageCorrect): Promise<Domain> {
        if (this.isAdmin) {
            return this.backendDomainsRepo
                .update(
                    this.customer.id,
                    this.domain.id,
                    {
                        id: this.domain.id,
                        settings: {
                            scripts: {
                                page_correct: pageCorrectSettings,
                            },
                        },
                    });
        }

        return this.customerRepo.updateDomain(this.customer.id, {
            id: this.domain.id,
            settings: {
                scripts: {
                    page_correct: pageCorrectSettings,
                },
            },
        } as DomainDataType);
    }
}
