<mon-page-header
    [monIcon]="['USERS', 'ICON']"
    header="{{'Search users'}}">
</mon-page-header>

<form name="form" class="row row-cols-lg-auto g-3 align-items-center" #form="ngForm" (ngSubmit)="search()">

    <div class="col-45 col-md-45">
        <mon-form-field-input [(ngModel)]="search_string"
                              monLabel="{{ 'Search users' | translate }}"
                              monPlaceholder="{{ 'User mail or ID' | translate }}"
                              [type]="'text'"
                              #searchString="ngModel"
                              [name]="'searchString'"
                              data-test="search-input"
                              >
        </mon-form-field-input>
        <mon-form-field-errors
            [showError]="searchString.control.touched && searchString.control.invalid"
            [errors]="searchString.control.errors">
        </mon-form-field-errors>
    </div>

    <div class="col-3 col-md-3 mt-1">
        <button class="btn btn-secondary pull-right" [disabled]="searching || form.invalid" type="submit" data-test="search-button">
            <span [hidden]="searching" translate>Search</span>
            <mon-icon [hidden]="!searching"
                  [spin]="searching"
                  [icon]="['SPINNER', 'ICON']">
            </mon-icon>
        </button>
    </div>
</form>

<div class="card monsido-panel mt-8">
    <div class="card-body">
        <div class="table-container-body">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th translate>Environment</th>
                        <th translate>Id</th>
                        <th translate>Email</th>
                        <th translate>Customers</th>
                        <th translate>Go to env</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let env of this.envs">
                        <tr>
                            <td colspan="5">
                                <span [ngClass]="{'fst-italic': env.users.total === 0}" translate>Users </span>
                                <ng-container *ngIf="env.users.length > 0">
                                    <span translate [translate-args]="{length: env.users.length, total: env.users.total}">&nbsp;[[length]] out of [[total]]</span>
                                </ng-container>
                                <ng-container *ngIf="env.users.length === 0">
                                    <span  class="fst-italic" translate>&nbsp; - No matches found</span>
                                </ng-container>
                                <ng-container *ngIf="env.users.total > 10">
                                    <span translate>&nbsp;(only showing first 10 results - refine your search to reduce results)</span>
                                </ng-container>
                            </td>
                        </tr>
                        <ng-container *ngFor="let user of env.users">
                            <tr>
                                <td>
                                    {{ env.name}}
                                </td>
                                <td>
                                    {{ user.id }}
                                </td>
                                <td>
                                    {{ user.email }}
                                </td>
                                <td>
                                    <ng-container *ngFor="let agreement of user.user_agreements">
                                        <ul class="list-unstyled d-flex flex-column text-ellipsis"
                                            *ngIf="agreement.account && agreement.account.type == 'Customer'">
                                            <li class="text-ellipsis">
                                                {{agreement.account.name}}
                                            </li>
                                        </ul>
                                    </ng-container>
                                </td>
                                <td>
                                    <button type="button"
                                            (click)="goToEnv(env, user)"
                                            class="btn btn-secondary"
                                            data-test="go-to-button">
                                        <span translate>Go to env</span>&nbsp;
                                        <span class="fas fa-arrow-right" aria-hidden="true"></span>
                                    </button>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>

