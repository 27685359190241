import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionService } from '@monsido/core/session/session.service';
import { LANGUAGE_CONSTANT } from '@monsido/core/constants/languages.constant';
import { EmailValidatorService } from '@monsido/modules/utils/email-validator.service';
import { UserInterface } from '@monsido/modules/models/api/interfaces/user.interface';
import { LanguageInterface } from '@monsido/modules/models/api/interfaces/language.interface';
import { FormsModule, NgForm } from '@angular/forms';
import { FormBuilderInputModule, FormBuilderSelectModule, FormBuilderValidationModule, MonSwitchModule } from '@monsido/angular-shared-components';
import { distinctUntilChanged, of, switchMap } from 'rxjs';
import { TranslateModule } from 'app/modules/translate.module';

@Component({
    selector: 'mon-form-backend-admin-user-basic',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        FormBuilderInputModule,
        FormBuilderSelectModule,
        FormBuilderValidationModule,
        MonSwitchModule,
        TranslateModule,
    ],
    templateUrl: './form-backend-admin-user-basic.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormBackendAdminUserBasicComponent implements OnInit, AfterViewInit {

    @Input() user: UserInterface;
    @Output() isFormValid: EventEmitter<boolean> = new EventEmitter(false);
    @ViewChild('basicForm') basicForm: NgForm;

    emailValidator: string;
    supportedLanguages: LanguageInterface[];
    isAdmin = false;

    constructor (
        private sessionService: SessionService,
        private emailValidatorService: EmailValidatorService,
        private cdref: ChangeDetectorRef,
    ) {}

    ngOnInit (): void {
        this.emailValidator = this.emailValidatorService.getEmailPattern();
        this.supportedLanguages = [LANGUAGE_CONSTANT.en, LANGUAGE_CONSTANT.da];

        if (this.user) {
            this.user.send_welcome_email = true;
        }
        this.sessionService.getUser().then(() => {
            if (this.sessionService.isBackendAdmin()) {
                this.isAdmin = true;
                this.cdref.detectChanges();
            }
        }, () => {});
    }

    ngAfterViewInit (): void {
        this.basicForm?.form.valueChanges.pipe(
            switchMap(() => of(this.basicForm.form.valid)),
            distinctUntilChanged(),
        ).subscribe((formValid: boolean) => {
            this.isFormValid.emit(formValid);
        });
    }

}
