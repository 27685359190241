import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { ConstraintsAndExcludesComponent } from './constraints-and-excludes.component';
import { CONSTRAINTS_AND_EXCLUDES_STATES } from './constraints-and-excludes.state';
import { LayoutModule, IconsModule, TableModule, FormsBuilderModule } from '@monsido/angular-shared-components';
import { CommonModule } from '@angular/common';
import { ConstraintsAndExcludesEditorComponent } from './constraints-and-excludes-editor/constraints-and-excludes-editor.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from 'app/modules/translate.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UIRouterModule.forChild({ states: CONSTRAINTS_AND_EXCLUDES_STATES }),
        LayoutModule,
        TranslateModule,
        IconsModule,
        TableModule,
        FormsBuilderModule,
    ],
    exports: [],
    declarations: [
        ConstraintsAndExcludesComponent,
        ConstraintsAndExcludesEditorComponent,
    ],
    providers: [],
})
export class ConstraintsAndExcludesModule {}
