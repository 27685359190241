import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseLayoutModule } from '@layout/layout.module';
import {
    TableModule,
    TableSearchModule,
    LayoutModule,
    TabsModule,
    IconsModule,
    MonTableSortModule,
} from '@monsido/angular-shared-components';
import { ConfirmModule } from '@monsido/confirm/confirm.module';
import { ParamModule } from '@monsido/core/param/param.module';
import { PipesModule } from '@monsido/core/pipes/pipes.module';
import { ApiModule } from '@monsido/modules/endpoints/api/api.module';
import { ServicesModule } from '@monsido/services/services.module';
import { NgbDropdownModule, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';
import { MomentModule } from 'ngx-moment';
import { SearchService } from '../data-privacy-checks/helper/search.service';
import { BackendAdminCrawlQueueComponent } from './crawl-queue.component';
import { BackendAdminCrawlQueueService } from './crawl-queue.service';
import { BACKEND_ADMIN_CRAWL_QUEUE_STATES } from './crawl-queue.state';
import { TranslateModule } from 'app/modules/translate.module';

@NgModule({
    imports: [
        ParamModule,
        ApiModule,
        BaseLayoutModule,
        LayoutModule,
        UIRouterModule.forChild({
            states: BACKEND_ADMIN_CRAWL_QUEUE_STATES,
        }),
        TabsModule,
        ServicesModule,
        CommonModule,
        MomentModule,
        ConfirmModule,
        TableModule,
        NgbTooltipModule,
        TranslateModule,
        TableSearchModule,
        NgbModule,
        NgbDropdownModule,
        PipesModule,
        IconsModule,
        MonTableSortModule,
    ],
    exports: [],
    declarations: [BackendAdminCrawlQueueComponent],
    providers: [BackendAdminCrawlQueueService, SearchService],
})
export class BackendAdminCrawlQueueModule {}
