import { Component, OnInit } from '@angular/core';
import { ToastService } from '@monsido/angular-shared-components';
import { Injectable } from '@angular/core';
import { BackendAdminCrawlersService } from './crawler.service';
import { BackendCrawlerScaleStatusInterface } from '@monsido/modules/endpoints/api/backend_admin/backend-crawler-scale-status.interface';
import { TranslateService } from 'app/services/translate/translate.service';
@Component({
    selector: 'mon-crawlers',
    templateUrl: 'crawler.html',
})
@Injectable()
export class BackendAdminCrawlersComponent implements OnInit {
    loading: boolean;
    crawlers: Record<string, unknown>[] = [];
    scaleStatus: BackendCrawlerScaleStatusInterface = {
        workers: [],
        running: null,
        stopped: null,
        wanted: null,
        current_scale: null,
    };
    constructor (
        private toastService: ToastService,
        private translateService: TranslateService,
        private backendAdminCrawlersService: BackendAdminCrawlersService,
    ) {}

    ngOnInit (): void {
        this.getPage();
        this.getScaleStatus();
    }

    getPage (): void {
        this.loading = true;
        this.backendAdminCrawlersService.getPage().then(
            (data: Record<string, string>[]) => {
                this.loading = false;
                this.crawlers = this.backendAdminCrawlersService.sortCrawlers(data);
            },
            (res) => {
                this.loading = res.status === -1 && res.xhrStatus === 'abort';
            },
        );
    }

    getScaleStatus (): void {
        this.backendAdminCrawlersService.getScaleStatus().then(
            (scaleStatus) => {
                this.scaleStatus = scaleStatus;
                this.getPage();
            },
            () => {},
        );
    }

    scaleUp (): void {
        this.backendAdminCrawlersService
            .scaleUp()
            .then(
                () => {
                    this.toastService.success(this.translateService.getString('Crawler scaled up'));
                    this.getPage();
                },
                () => {},
            )
            .finally(() => {
                this.getScaleStatus();
            });
    }

    scaleDown (): void {
        this.backendAdminCrawlersService
            .scaleDown()
            .then(
                () => {
                    this.toastService.success(this.translateService.getString('Crawler scaled down'));
                    this.getPage();
                },
                () => {},
            )
            .finally(() => {
                this.getScaleStatus();
            });
    }

    pauseCrawlers (): void {
        this.backendAdminCrawlersService.pauseCrawlers().then(
            () => {
                this.toastService.success(this.translateService.getString('Crawler now paused'));
                this.getPage();
            },
            () => {},
        );
    }

    resumeCrawlers (): void {
        this.backendAdminCrawlersService.resumeCrawlers().then(
            () => {
                this.toastService.success(this.translateService.getString('Crawler now resumed'));
                this.getPage();
            },
            () => {},
        );
    }
}
