import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { SessionService } from '@monsido/core/session/session.service';
import { StateService } from '@uirouter/core';
import { User } from '@monsido/modules/models/api/user';
import { BackendUserRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-user-repo';

@Injectable()
export class SearchUsersService {
    constructor (
        private userRepo: BackendUserRepo,
        private sessionService: SessionService,
        private state: StateService,
    ) {}

    searchUsers (searchString: string, url: string): Promise<User[]> {
        return this.userRepo.getAll(
            { search: searchString },
            new HttpHeaders({ noGlobal: 'true', noParseSearch: 'true' }),
            url + '/admin/api/',
        );
    }

    selectEnv (url: string): Promise<void[] | void> {
        return this.sessionService.setSelectedApi(url);
    }

    async redirectToEnv (user: User, url: string, envName: string): Promise<void> {
        await this.selectEnv(url);
        await this.state.reload();
        this.state.go('base.backend_admin.users.index', {
            search: user.email,
            env: envName,
        });
    }
}
