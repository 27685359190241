import { Injectable } from '@angular/core';
import { BackendPlansRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-plans.repo';
import { PLAN } from '@monsido/core/constants/plan.constant';
import { FormBackendAdminPlanComponent } from '@monsido/forms/backend_admin/plan/plan.component';
import { DialogService, ToastService } from '@monsido/angular-shared-components';
import { Plan } from '@monsido/modules/models/api/plan';
import { TranslateService } from 'app/services/translate/translate.service';

@Injectable()
export class PlansService {
    planGroups = PLAN.PLAN_GROUPS;
    constructor (
        private backendPlansRepo: BackendPlansRepo,
        private toastService: ToastService,
        private translateService: TranslateService,
        private dialogService: DialogService,
    ) {}

    getAll (params: Record<string, string | number>): Promise<Plan[]> {
        return this.backendPlansRepo.getAll(params);
    }

    createPlan (cb: (...args: unknown[]) => void): void {
        this.dialogService.open(FormBackendAdminPlanComponent, {
            classes: 'mon-dialog-size-sm',
            formConfirmPromptParentSelector: '#common-dialog-wrapper',
            defaultWrapper: false,
            cb,
        });
    }

    editPlan (plan: Plan, cb: (...args: unknown[]) => void): void {
        const dialogRef = this.dialogService.open(FormBackendAdminPlanComponent, {
            classes: 'mon-dialog-size-sm',
            formConfirmPromptParentSelector: '#common-dialog-wrapper',
            defaultWrapper: false,
            cb,
        });

        dialogRef.componentInstance.plan = plan;
    }

    deletePlan (planId: number): Promise<void> {
        return this.backendPlansRepo.destroy(planId).then(
            () => {
                this.toastService.success(this.translateService.getString('Plan is deleted'));
            },
            () => {},
        );
    }

    groupName (groupId: number): string {
        for (const planGroup of this.planGroups) {
            if (planGroup.id === groupId) {
                return planGroup.name;
            }
        }
        return 'Unkown';
    }

    sortPlans (planArr: Plan[]): Plan[] {
        return planArr.sort((a, b) => {
            const aUp = a.name.toUpperCase();
            const bUp = b.name.toUpperCase();
            if (aUp < bUp) {
                return -1;
            } else if (aUp > bUp) {
                return 1;
            } else {
                return 0;
            }
        });
    }
}
