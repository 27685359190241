import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Customer } from '@monsido/modules/models/api/customer';
import { Domain } from '@monsido/modules/models/api/domain';
import { TranslateReadabilityPipe } from '@monsido/core/pipes/translateReadability/translateReadability.pipe';
import { ControlContainer, NgForm } from '@angular/forms';
import { DialogService } from '@monsido/angular-shared-components';
import { PageFixDomainSettingsComponent } from '@monsido/forms/backend_admin/page-fix-domain-settings/page-fix-domain-settings.component';
import { FormBackendAdminPageAssistComponent } from '@monsido/forms/backend_admin/page-assist/page-assist.component';
import { PageAssistSettings, PageCorrect } from 'types/domain';
import { TranslateService } from 'app/services/translate/translate.service';
import {
    AccessibilityGuidelineInfoService, AccessibilityGuidelineType,
} from '../../../../../app/blocks/helpers/accessibility/accessibility-guideline-info.service';

interface AccessibilityOption {
    accessibility_guideline_id: string;
    name: string;
}

interface ReadabilityOption {
    readability_test_id: string;
    name: string;
}

interface ClarityOption {
    guideline_id: string;
    name: string;
}

@Component({
    selector: 'mon-form-domain-features',
    templateUrl: 'features.html',
    styleUrls: ['./features.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormDomainFeaturesComponent implements OnInit {
    @Input() monDomain: Domain;
    @Input() monCustomer: Customer;
    @Input() monForm: NgForm;
    @Input() monIsBackendAdmin: boolean = false;

    @ViewChild('domainFeatureFieldsForm') public form: NgForm;

    accessibilities: AccessibilityOption[] = [];
    readabilities: ReadabilityOption[] = [];
    clarityOptions: ClarityOption[] = [];
    translateReadabilityPipe: TranslateReadabilityPipe;
    featureAccessibilityGuidelineId = '';

    showAccessibilityErrorsOnlyCheck = false;
    initialized = false;

    constructor (
        private translateService: TranslateService,
        private dialogService: DialogService,
        private accessibilityGuidelineInfoService: AccessibilityGuidelineInfoService,
    ) {
        this.translateReadabilityPipe = new TranslateReadabilityPipe(this.translateService);
    }

    ngOnInit (): void {
        this.featureAccessibilityGuidelineId = (this.monDomain.features.accessibility || '').split('_v')[0];
        const type = this.accessibilityGuidelineInfoService.getAccessibilityTypeFromGuidelineValue(this.featureAccessibilityGuidelineId);
        this.setAccessibilityErrorsOnlyCheckInputVisibility(type);

        this.monDomain.features = {
            ...this.monDomain.features,
            statistics: this.monDomain.features.statistics || false,
            accessibility: this.monDomain.features.accessibility || null,
            readability_test: this.monDomain.features.readability_test || null,
            page_assist: this.monDomain.features.page_assist || false,
        };

        const showMobileGuidelines = this.monCustomer?.plan_traits?.mobile_guidelines;
        const guidelineMap = this.accessibilityGuidelineInfoService.guidelineValueNameMap;

        for (const [guideline, name] of Object.entries(guidelineMap)) {
            if (guideline.includes('mobile') && !showMobileGuidelines) {
                continue;
            }
            this.accessibilities.push(this.createGuidelineOption(guideline, name));
        }

        this.readabilities = [
            {
                readability_test_id: 'flesch_kincaid_re',
                name:
                    this.translateReadabilityPipe.transform('flesch_kincaid_re') +
                    ' ' +
                    this.translateService.getString('Readability test (for English pages only)'),
            },
            {
                readability_test_id: 'lix',
                name: this.translateReadabilityPipe.transform('lix') + ' ' + this.translateService.getString('Readability test'),
            },
        ];
        this.clarityOptions = [
            {
                guideline_id: 'WCAG2_0',
                name: this.translateService.getString('WCAG 2.0'),
            },
            {
                guideline_id: 'WCAG2_1',
                name: this.translateService.getString('WCAG 2.1'),
            },
        ];
        this.initialized = true;
    }

    openPageAssist (): void {
        const dialogRef = this.dialogService.open(FormBackendAdminPageAssistComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb: (settings: PageAssistSettings) => {
                if (settings) {
                    this.monDomain.page_assist_settings = settings;
                }
            },
        });
        dialogRef.componentInstance.domain = this.monDomain;
        dialogRef.componentInstance.customer = this.monCustomer;
        dialogRef.componentInstance.saveToAPI = false;
    }

    openPageFix (): void {
        const dialogRef = this.dialogService.open(PageFixDomainSettingsComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb: (settings: PageCorrect) => {
                if (settings) {
                    this.monDomain.settings.scripts.page_correct.admin_only = settings.admin_only;
                    this.monDomain.settings.scripts.page_correct.enabled_checks = settings.enabled_checks;
                }
            },
        });
        dialogRef.componentInstance.domain = this.monDomain;
        dialogRef.componentInstance.customer = this.monCustomer;
        dialogRef.componentInstance.saveToAPI = false;
    }

    createGuidelineOption (guideline: string, name: string): AccessibilityOption {
        return {
            name,
            accessibility_guideline_id: guideline,
        };
    }

    onGuidelineChange = (): void => {
        const type = this.accessibilityGuidelineInfoService.getAccessibilityTypeFromGuidelineValue(this.featureAccessibilityGuidelineId || '');
        let accessibilityVersion = 0;
        if (type) {
            accessibilityVersion = this.accessibilityGuidelineInfoService.guidelineVersionsMap[type];
        }

        this.setAccessibilityErrorsOnlyCheckInputVisibility(type);

        if (this.featureAccessibilityGuidelineId) {
            this.monDomain.features.accessibility = `${this.featureAccessibilityGuidelineId}_v${accessibilityVersion}`;
        } else {
            this.monDomain.features.accessibility = null;
        }
    };

    private setAccessibilityErrorsOnlyCheckInputVisibility (type: AccessibilityGuidelineType | null): void {
        this.showAccessibilityErrorsOnlyCheck = type === 'wcag';
        if (!this.showAccessibilityErrorsOnlyCheck) {
            this.monDomain.features.accessibility_errors_only = false;
        }
    }

}
