import { Component, OnInit } from '@angular/core';
import { ActiveDialog } from '@monsido/angular-shared-components';
import { CookieImportStepType, ImportCookiesService } from '@monsido/pages/backend-admin/cookies/repo/forms/import/import-cookies.service';
import { Observable } from 'rxjs';
import { ParseResult } from 'ngx-papaparse';
import { UpdateType } from '@monsido/pages/backend-admin/cookies/repo/forms/import/setup/setup.component';
import { CookieDescriptionLanguages } from '@monsido/modules/models/support/interfaces/cookie.interface';
import { Cookie } from '@monsido/modules/models/support/cookie';
import { find } from 'lodash';
import { CookieService } from '@monsido/pages/backend-admin/cookies/repo/cookie.service';

interface SelectLanguagesOption {
    value: CookieDescriptionLanguages;
    name: string;
}

@Component({
    selector: 'mon-form-backend-admin-import-cookies',
    templateUrl: 'import.html',
})
export class FormImportCookieComponent implements OnInit {
    cookiesForCreate: Cookie[] = [];
    cookiesForUpdate: Cookie[] = [];
    stepObs: Observable<CookieImportStepType>;
    typeDropdownLanguages: SelectLanguagesOption[];
    saving: boolean;
    language: CookieDescriptionLanguages;
    data: ParseResult;
    selected: UpdateType;

    constructor (private activeDialog: ActiveDialog, private importService: ImportCookiesService, private cookieService: CookieService) {}

    ngOnInit (): void {
        this.stepObs = this.importService.getStepObs();
        this.data = null;
        this.selected = { platform: null, category: null, name: null, description: null, retention: null };
        this.typeDropdownLanguages = [
            { value: CookieDescriptionLanguages.EN_US, name: 'English(US)' },
            { value: CookieDescriptionLanguages.DA_DK, name: 'Danish' },
            { value: CookieDescriptionLanguages.DE_DE, name: 'German(Germany)' },
            { value: CookieDescriptionLanguages.ES_ES, name: 'Spanish (Spain)' },
            { value: CookieDescriptionLanguages.NB_NO, name: 'Norwegian Bokmål' },
            { value: CookieDescriptionLanguages.NL_NL, name: 'Dutch' },
        ];
    }

    incrementStep (): void {
        this.importService.setStep((this.importService.getCurrentStep() + 1) as CookieImportStepType);
    }

    onUploadDone (data: ParseResult): void {
        this.data = data;
    }

    onUpdateSelected (event: UpdateType): void {
        this.selected = event;
    }

    prepareImport (): void {
        const nameVal: string | number = this.selected.selected.name;
        const platformVal: string | number = this.selected.selected.platform;
        const categoryVal: string | number = this.selected.selected.category;
        const descriptionVal: string | number = this.selected.selected.description;
        const retentationVal: string | number = this.selected.selected.retention;

        this.cookieService.getAllCookies().then((data: Cookie[]) => {
            const cookies = data;
            this.data.data.shift();
            for (let i = 0; i < this.data.data.length; i++) {
                const cookie = this.data.data[i];
                if (cookie[nameVal]) {
                    const tempCookie: Cookie = find(cookies, {
                        name: cookie[nameVal],
                        platform: cookie[platformVal],
                    });
                    if (tempCookie) {
                        this.cookiesForUpdate.push(
                            this.importService.prepareUpdateCookie(
                                this.language,
                                cookie[platformVal],
                                cookie[categoryVal],
                                cookie[descriptionVal],
                                cookie[retentationVal],
                                tempCookie,
                            ),
                        );
                    } else {
                        this.cookiesForCreate.push(
                            this.importService.prepareNewCookie(
                                this.language,
                                cookie[nameVal],
                                cookie[platformVal],
                                cookie[categoryVal],
                                cookie[descriptionVal],
                                cookie[retentationVal],
                            ),
                        );
                    }
                }
            }
            this.incrementStep();
        });
    }

    submit (): void {
        this.saving = true;
        this.close();
    }

    close (): void {
        this.importService.importCookies(this.cookiesForCreate.concat(this.cookiesForUpdate)).then(() => {
            this.activeDialog.close();
        });
    }
}
