import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormDomainFeaturesComponent } from '@monsido/forms/domain/steps/features/features.component';
import { FormsBuilderModule, MonSwitchModule } from '@monsido/angular-shared-components';
import { PipesModule } from '@monsido/core/pipes/pipes.module';
import { TranslateModule } from 'app/modules/translate.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [FormDomainFeaturesComponent],
    imports: [
        CommonModule,
        TranslateModule,
        FormsBuilderModule,
        PipesModule,
        MonSwitchModule,
        NgbTooltipModule,
    ],
    exports: [FormDomainFeaturesComponent],
})
export class FeaturesModule {}
