import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { UserAgreementsEntity, UserInterface } from '@monsido/modules/models/api/interfaces/user.interface';
import { UrlService } from '@uirouter/core';
import { ParamService } from '@monsido/core/param/param.service';
import { BackendAdminUserRepo } from 'app/services/api/backend_admin/backend-admin-user-repo.service';
import { LayoutModule, MonPromptService, TableModule } from '@monsido/angular-shared-components';
import { HttpHeaders } from '@angular/common/http';
import { BackendAdminCustomersRepo } from 'app/services/api/backend_admin/backend-admin-customers-repo';
import { ResellersRepo } from 'app/services/api/backend_admin/resellers-repo';
import { BackendAdminUsersTableComponent } from '../backend-admin-users-table/backend-admin-users-table.component';
import { ConfirmModule } from '@monsido/confirm/confirm.module';
import { TranslateModule } from 'app/modules/translate.module';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-backend-admin-users',
    standalone: true,
    imports: [CommonModule, LayoutModule, TableModule, BackendAdminUsersTableComponent, TranslateModule, ConfirmModule],
    templateUrl: './backend-admin-users.component.html',
    styleUrls: ['./backend-admin-users.component.scss'],
})
export class BackendAdminUsersComponent extends BaseApiComponent implements OnInit {
    users: UserInterface[];
    selectedUsers: UserInterface[];
    removingSelectedUsers: boolean;
    promise: Promise<unknown>;

    constructor (
        private paramService: ParamService,
        private backendAdminUserRepo: BackendAdminUserRepo,
        private promptService: MonPromptService,
        private translateService: TranslateService,
        private backendAdminCustomersRepo: BackendAdminCustomersRepo,
        private resellersRepo: ResellersRepo,
        protected urlService: UrlService,
    ) {
        super(urlService);
    }

    ngOnInit (): void {
        this.users = [];
        this.selectedUsers = [];
        this.removingSelectedUsers = false;
        this.loadLocations();
    }

    getPage (): void {
        this.paramService.setParams({
            search: this.search,
            page_size: this.pageSize,
            page: this.page,
        });
        this.getUsers();
    }

    getUsers (): void {
        const params = {
            page: this.page,
            page_size: this.pageSize,
            search: this.search,
        };
        this.loading = true;
        this.promise = this.backendAdminUserRepo.getAll(params, undefined).then(
            (data) => {
                this.users = data;
                this.loading = false;
            },
            (res) => {
                this.loading = res.status === -1 && res.xhrStatus === 'abort';
            },
        );
    }

    deleteUsers (): void {
        const chain: Promise<void>[] = [];
        this.removingSelectedUsers = true;
        this.selectedUsers.forEach((user) => {
            chain.push(...this.deleteUser(user));
        });

        Promise.all(chain).finally(() => {
            this.promptService.alert(this.translateService.getString('Selected users have been removed'));
            setTimeout(() => {
                this.removingSelectedUsers = false;
                this.selectedUsers.length = 0;
                this.page = 1;
                this.getPage();
            }, 200);
        });
    }

    deleteUser (user: UserInterface): Promise<void>[] {
        const chain: Promise<void>[] = [];

        user.user_agreements.forEach((agreement) => {
            if (agreement.account && agreement.account.type === 'Reseller') {
                chain.push(this.deleteUserFromReseller(user, agreement));
            } else {
                chain.push(this.deleteUserFromCustomer(user, agreement));
            }
        });
        return chain;
    }

    deleteUserFromCustomer (user: UserInterface, agreement: UserAgreementsEntity): Promise<void> {
        const headers = new HttpHeaders({ noGlobal: 'true' });
        return this.backendAdminCustomersRepo.destroyUser(agreement.account.id, user.id, headers);
    }

    deleteUserFromReseller (user: UserInterface, agreement: UserAgreementsEntity): Promise<void> {
        const headers = new HttpHeaders({ noGlobal: 'true' });
        return this.resellersRepo.destroyUser(agreement.account.id, user.id, undefined, headers);
    }
}
