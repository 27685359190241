import { Injectable, Type } from '@angular/core';
import { StateService, TransitionPromise } from '@uirouter/core';
import { SearchService } from '@monsido/pages/backend-admin/data-privacy-checks/helper/search.service';
import { CrawlQueue } from '@monsido/modules/models/api/crawl-queue';
import { DialogService } from '@monsido/angular-shared-components';
import { FormBackAdminCrawlDetailsComponent } from '@monsido/forms/backend_admin/crawl-details/crawl-details.component';
import { FormAdminCrawlDetailsComponent } from '@monsido/forms/reseller-admin/crawl-details/crawl-details.component';
import { Domain } from '@monsido/modules/models/api/interfaces/crawl-queue.interface';

type CrawlOpenDialogType = {
    component: Type<FormBackAdminCrawlDetailsComponent | FormAdminCrawlDetailsComponent>;
    classes?: string;
};

@Injectable()
export class CrawlQueueCommonService {
    constructor (private state: StateService, private dialogService: DialogService, private searchService: SearchService) {}

    goToDomain (domain: Domain, url: string): TransitionPromise {
        return this.state.go(url, {
            customer_id: domain.customer_id,
            domain_id: domain.id,
        });
    }

    getCrawls (crawls: CrawlQueue[], search: string): CrawlQueue[] {
        if (!search) {
            return crawls;
        }

        return crawls.filter((crawl) => {
            const url = crawl.domain?.url || '';
            const conditions = [
                (): boolean => this.searchService.fuzzy(search, crawl.title),
                (): boolean => this.searchService.fuzzy(search, url),
                (): boolean => this.searchService.fuzzy(search, crawl.customer.name),
                (): boolean => this.searchService.fuzzy(search, crawl.reseller_name),
            ];

            const domainId = crawl?.domain?.id?.toString();
            if (domainId) {
                conditions.push(() => this.searchService.fuzzy(search, domainId));
            }
            const customerId = crawl?.customer?.id?.toString();
            if (customerId) {
                conditions.push(() => this.searchService.fuzzy(search, customerId));
            }
            return conditions.some((condition) => condition());
        });
    }

    openDialog (crawl: CrawlQueue, cb: (...args: unknown[]) => void, options: CrawlOpenDialogType): void {
        const dialogRef = this.dialogService.open(options.component, {
            defaultWrapper: false,
            classes: options.classes,
            cb,
        });
        dialogRef.componentInstance.crawl = crawl;
    }
}
