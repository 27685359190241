import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Domain } from '@monsido/modules/models/api/domain';
import { NgForm } from '@angular/forms';
import { ActiveDialog } from '@monsido/angular-shared-components';
import { CustomerRepo } from '@monsido/modules/endpoints/api/admin/customer.repo';
import { User } from '@monsido/modules/models/api/user';
import { CollectionInterface } from '@monsido/modules/endpoints/api/collection.interface';
import { cloneDeep, isEqual } from 'lodash';
import {
    AdditionalInternalUrls,
    BasicAuthLoginType,
    CustomMultistepLoginType,
    DomainScanLogin,
    FormLoginType,
    LinkExcludes,
    Office365LoginType,
    PageAssistSettings,
    PathConstraints,
} from 'types/domain';
import { BackendDomainsRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-domains.repo';
import { BackendCustomerRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-customer.repo';
import { BaseForm } from '../base-form';
import { Customer } from '@monsido/modules/models/api/customer';
import { FormDomainDetailComponent } from '@monsido/forms/domain/steps/detail/detail.component';
import { FormDomainScanComponent } from '@monsido/forms/domain/steps/scan/scan.component';
import { FormDomainUsersComponent } from '@monsido/forms/domain/steps/users/users.components';
import { FormDomainFeaturesComponent } from '@monsido/forms/domain/steps/features/features.component';

@Component({
    selector: 'mon-form-domain',
    templateUrl: 'domain.html',
})
export class FormDomainComponent extends BaseForm implements OnInit, AfterViewInit {
    formDomain: Domain;
    @Input() monDomain: Domain;
    @Input() monCustomer: Customer;
    @Input() monIsBackendAdmin: boolean;

    saving: boolean;
    users: CollectionInterface<User>;
    originalDomain: Domain;

    @ViewChild('domainForm', { static: false }) domainForm: NgForm;
    @ViewChild('domainDetailForm') domainDetailForm: FormDomainDetailComponent;
    @ViewChild('domainScanForm') domainScanForm: FormDomainScanComponent;
    @ViewChild('domainFeaturesForm') domainFeaturesForm: FormDomainFeaturesComponent;
    @ViewChild('domainUsersForm') domainUsersForm: FormDomainUsersComponent;

    constructor (
        private activeDialog: ActiveDialog,
        private customerRepo: CustomerRepo,
        private backendCustomerRepo: BackendCustomerRepo,
        private backendDomainsRepo: BackendDomainsRepo,
    ) {
        super();
    }

    async ngOnInit (): Promise<void> {
        this.formDomain = cloneDeep(this.monDomain);
        this.setCmsExtra();
        await this.getCustomer();
        this.resetFormState(this.domainForm);
    }

    ngAfterViewInit (): void {
        if (this.domainForm) {
            setTimeout(() => this.resetFormState(this.domainForm));
            this.activeDialog.setForm(this.domainForm);
        }
        this.originalDomain = this.getOriginalDomain();
    }

    setCmsExtra (): void {
        if (this.formDomain.cms_extra === null) {
            this.formDomain.cms_extra = this.formDomain.getDefaultCmsExtra();
        }
    }

    async getCustomer (): Promise<void> {
        if (!this.monCustomer) {
            if (this.monIsBackendAdmin) {
                this.monCustomer = await this.backendCustomerRepo.get(this.monDomain.customer_id);
            } else {
                this.monCustomer = await this.customerRepo.get(this.monDomain.customer_id);
            }
            await this.getUsers();
        } else {
            await this.getUsers();
        }
    }

    async getUsers (): Promise<void> {
        if (this.monIsBackendAdmin) {
            this.users = await this.backendCustomerRepo.getAllUsers(this.monCustomer.id, {
                page_size: 0,
            });
        } else {
            this.users = await this.customerRepo.getAllUsers(this.monCustomer.id, {
                page_size: 0,
            });
        }
    }

    async submit (): Promise<void> {
        if (!this.domainForm.touched && isEqual(this.originalDomain, this.formDomain)) {
            this.domainForm.control.markAsPristine();
            this.close();
            return;
        }
        const domain = cloneDeep(this.formDomain);
        domain.scan.max_scan_depth = this.formatScanMaxProps(domain.scan.max_scan_depth);
        const { day, time } = this.formDomain.getScanDayTimeUTC();
        domain.scan.day = day;
        domain.scan.time = time;

        if (domain.link_excludes) {
            domain.link_excludes = this.clearEmptyLinkExcludes(domain.link_excludes);
        }
        if (domain.path_constraints) {
            domain.path_constraints = this.clearEmptyPathConstraints(domain.path_constraints);
        }
        domain.additional_internal_urls = this.clearEmptyInternalUrls(domain.additional_internal_urls);
        domain.page_assist_settings = this.setupPageAssistSettings(domain.page_assist_settings);
        domain.language = !domain.language ? null : domain.language;
        domain.scan.login = this.setupScanLogin(domain.scan.login);

        if (typeof domain.customer_id !== 'number') {
            domain.customer_id = this.monCustomer.id;
        }

        let result;
        try {
            this.saving = true;
            if (this.monIsBackendAdmin) {
                if (typeof this.formDomain.id === 'number') {
                    result = await this.backendDomainsRepo.update(this.monCustomer.id, domain.id, domain);
                } else {
                    result = await this.backendDomainsRepo.create(this.monCustomer.id, domain);
                }
            } else {
                if (typeof this.formDomain.id === 'number') {
                    result = await this.customerRepo.updateDomain(this.monCustomer.id, domain);
                } else {
                    result = await this.customerRepo.createDomain(this.monCustomer.id, domain);
                }
            }
            this.monDomain = result;
            this.resetFormState(this.domainForm);
            this.close(true);
        } finally {
            this.saving = false;
        }
    }

    //

    private formatScanMaxProps (prop: string | number): number | null {
        if (prop === '' || prop == null || isNaN(Number(prop))) {
            return null;
        }

        return Number(prop);
    }

    private setupScanLogin (login: DomainScanLogin): DomainScanLogin | null {
        if (!login || login.type === 'none') {
            return null;
        } else {
            switch (login.type) {
                case 'custom_multistep':
                    return {
                        type: login.type,
                        verify: login.verify,
                        [login.type]: login[login.type] as CustomMultistepLoginType['custom_multistep'],
                    };
                case 'form':
                    return {
                        type: login.type,
                        verify: login.verify,
                        [login.type]: login[login.type] as FormLoginType['form'],
                    };
                case 'office365':
                    return {
                        type: login.type,
                        [login.type]: login[login.type] as Office365LoginType['office365'],
                    };
                case 'basic_auth':
                    return {
                        type: login.type,
                        [login.type]: login[login.type] as BasicAuthLoginType['basic_auth'],
                    };
                default:
                    return null;
            }
        }
    }

    private clearEmptyInternalUrls (internalUrls: AdditionalInternalUrls[]): AdditionalInternalUrls[] {
        return internalUrls.filter((url) => {
            return typeof url.value === 'string' && url.value.trim().length > 0;
        });
    }

    private clearEmptyPathConstraints (constraints: PathConstraints[]): PathConstraints[] {
        return constraints.filter((constraint) => {
            return constraint.constraint?.length > 0;
        });
    }

    private clearEmptyLinkExcludes (excludes: LinkExcludes[]): LinkExcludes[] {
        return excludes.filter((exclude) => {
            return exclude.regex?.length > 0;
        });
    }

    private close (forceUpdate?: boolean): void {
        this.activeDialog.close(forceUpdate);
    }

    private setupPageAssistSettings (settings: PageAssistSettings): PageAssistSettings {
        settings = settings || null;
        if (this.monCustomer.plan_traits.page_assist && settings !== null) {
            settings.main = '';
            settings.elements_to_hide = settings.elements_to_hide.filter(Boolean);

            settings.navigation = settings.navigation
                // eslint-disable-next-line space-before-function-paren
                .map(function (navigation) {
                    navigation.selectors = navigation.selectors.filter(Boolean);
                    return navigation;
                })
                // eslint-disable-next-line space-before-function-paren
                .filter(function (navigation) {
                    return (
                        typeof navigation.mainselector === 'string' && navigation.mainselector.length > 0 && navigation.selectors.length > 0
                    );
                });
        }
        return settings;
    }

    private getOriginalDomain (): Domain {
        return cloneDeep(this.formDomain);
    }
}
