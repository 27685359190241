import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastService, ActiveDialog } from '@monsido/angular-shared-components';
import { User } from '@monsido/modules/models/api/user';
import { BackendAdminUserForm } from './user-form';
import { BackendCustomerRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-customer.repo';
import { Domain } from '@monsido/modules/models/api/domain';
import { BaseForm } from '@monsido/forms/base-form';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-form-backend-admin-customer-user',
    templateUrl: 'user.html',
})
export class FormBackendAdminUserComponent extends BaseForm implements OnInit, AfterViewInit {
    @Input() user: User = new User();
    @Input() customerId: number;
    saving: boolean = false;
    page: number = 1;
    pageSize: number = 10;

    formUser: BackendAdminUserForm;
    @ViewChild('userForm', { static: false }) userForm: NgForm;

    domains: Domain[] = [];
    domainLoading: boolean;

    constructor (
        private activeDialog: ActiveDialog,
        private toastService: ToastService,
        private translateService: TranslateService,
        private backendCustomerRepo: BackendCustomerRepo,
    ) {
        super();
    }

    async ngOnInit (): Promise<void> {
        this.formUser = new BackendAdminUserForm(this.user);
        await this.getDomains();
    }

    ngAfterViewInit (): void {
        if (this.userForm) {
            setTimeout(() => this.resetFormState(this.userForm));
            this.activeDialog.setForm(this.userForm);
        }
    }

    async getDomains (): Promise<void> {
        try {
            this.domainLoading = true;
            this.domains = await this.backendCustomerRepo.getAllDomains(this.customerId, {
                page_size: this.pageSize,
                page: this.page,
            });
        } catch (e) {
            this.domains = [];
        } finally {
            this.domainLoading = false;
        }
    }

    onPageChange (page: number): void {
        this.page = page;
        this.getDomains();
    }

    onPageSizeChange (size: number): void {
        this.pageSize = size;
        this.page = 1;
        this.getDomains();
    }

    submit (): void {
        this.saving = true;
        if (this.formUser.id) {
            if (!this.formUser.password || this.formUser.password === this.formUser.password_confirmation) {
                this.backendCustomerRepo
                    .updateUser(this.customerId, this.formUser)
                    .then(
                        () => {
                            this.resetFormState(this.userForm);
                            this.close();
                            this.toastService.success(this.translateService.getString('User updated'));
                        },
                        () => {},
                    )
                    .finally(() => {
                        this.saving = false;
                    });
            } else {
                this.toastService.warning(this.translateService.getString('Passwords do not match'));
                this.saving = false;
            }
        } else {
            this.backendCustomerRepo
                .createUser(this.customerId, this.formUser)
                .then(
                    () => {
                        this.resetFormState(this.userForm);
                        this.close();
                        this.toastService.success(this.translateService.getString('User created'));
                    },
                    () => {},
                )
                .finally(() => {
                    this.saving = false;
                });
        }
    }

    close (): void {
        this.activeDialog.close(this.formUser);
    }
}
