import { Component, Input, OnInit, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { CustomerInterface } from '@monsido/modules/models/api/interfaces/customer.interface';
import { User } from '@monsido/modules/models/api/user';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { UrlService } from '@uirouter/core';
import { CollectionInterface } from '@monsido/modules/endpoints/api/collection.interface';
import { FormBackendAdminUserComponent } from '@monsido/forms/backend_admin/customer-user/user.component';
import { ToastService, MonPromptService, DialogService } from '@monsido/angular-shared-components';
import { BackendCustomerRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-customer.repo';
import { BackendUserRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-user-repo';
import { TranslateService } from 'app/services/translate/translate.service';

interface SudoEventInterface {
    customerId: number;
    user: User;
}

export interface GetUsersParamsInterface {
    search?: string;
    page: number;
    pageSize: number;
}

@Component({
    selector: 'mon-backend-admin-users-tab',
    templateUrl: './users-tab.html',
})
export class BackendAdminUsersTabComponent extends BaseApiComponent implements OnInit {
    @Input() customer: CustomerInterface;
    @Input() search: string;
    @Input() page: number;
    @Input() pageSize: number;
    @Input() usersArray: User[] = [];
    @Input() users: CollectionInterface<User>;
    @Input() loading: boolean;

    @Output() getPageEvent: EventEmitter<GetUsersParamsInterface> = new EventEmitter();
    @Output() createUserEvent: EventEmitter<unknown> = new EventEmitter();

    selectedUsers: User[] = [];
    showCustomer: boolean;

    constructor (
        private dialogService: DialogService,
        private translateService: TranslateService,
        private backendCustomerRepo: BackendCustomerRepo,
        private backendUserRepo: BackendUserRepo,
        private toastService: ToastService,
        private monPromptService: MonPromptService,
        $location: UrlService,
    ) {
        super($location);
    }

    ngOnInit (): void {
        if (this.customer) {
            this.getPage();
        }
    }

    ngOnChanges (changes: SimpleChanges): void {
        if (changes.users && changes.users.currentValue) {
            const { currentValue } = changes.users;
            this.users = currentValue;
            this.usersArray = Array.from(currentValue);
        }
    }

    getPage (): void {
        this.getPageEvent.emit({
            search: this.search,
            page: this.page,
            pageSize: this.pageSize,
        });
    }

    createUser (): void {
        this.createUserEvent.emit();
    }

    onSearchChanged (searchText: string): void {
        this.onSearch(searchText);
    }

    onEditUser (user: User): void {
        const dialogRef = this.dialogService.open(FormBackendAdminUserComponent, {
            defaultWrapper: false,
            classes: 'mon-dialog-size-sm',
            cb: () => {
                this.getPage();
            },
        });
        dialogRef.componentInstance.user = user;
        dialogRef.componentInstance.customerId = this.customer.id;
    }

    async onDeleteUser (user: User): Promise<void> {
        await this.backendCustomerRepo.destroyUser(this.customer.id, user.id);
        this.toastService.success(this.translateService.getString('User deleted'));
        this.getPage();
    }

    async deleteUsers (): Promise<void> {
        const promises = this.selectedUsers.map((user) => {
            return this.backendCustomerRepo.destroyUser(this.customer.id, user.id);
        });

        await Promise.all(promises);
        this.monPromptService
            .alert(this.translateService.getString('Selected customer-user deleted from reseller'))
            .then(
                () => {},
                () => {},
            )
            .finally(() => {
                this.selectedUsers.length = 0;
                this.page = 1;
                this.getPage();
            });
    }

    async onSudo (payload: SudoEventInterface): Promise<void> {
        const { customerId, user } = payload;
        await this.backendUserRepo.sudoUser(user.id, customerId);
    }
}
