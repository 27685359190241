/* eslint-disable @typescript-eslint/no-unused-vars */
import { OnInit, Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { UrlService } from '@uirouter/core';
import { CustomerInterface } from '@monsido/modules/models/api/interfaces/customer.interface';
import { CollectionInterface } from '@monsido/modules/endpoints/api/collection.interface';
import { Domain } from '@monsido/modules/models/api/domain';
import { JobService } from '@monsido/modules/job/job.service';
import { Job } from '@monsido/modules/models/api/job';
import { Customer } from '@monsido/modules/models/api/customer';
import { BackendDomainsRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-domains.repo';
import { ToastService, DialogService } from '@monsido/angular-shared-components';
import { FormDomainComponent } from '@monsido/forms/domain/domain.component';
import { cloneDeep } from 'lodash';
import { FormDomainURLRewriteComponent } from '@monsido/forms/backend_admin/domain/rewrite-url/rewrite-url.component';
import { FormBackendAdminPageAssistComponent } from '@monsido/forms/backend_admin/page-assist/page-assist.component';
import { JOB_TYPES } from '@monsido/modules/job/job.constant';
import { PageFixDomainSettingsComponent } from '@monsido/forms/backend_admin/page-fix-domain-settings/page-fix-domain-settings.component';
import { JobInterface } from '@monsido/modules/models/api/interfaces/job.interface';
import { TranslateService } from 'app/services/translate/translate.service';

interface ComputedDomain extends Domain {
    hasAccessibility: boolean;
    hasStatistics: boolean;
    hasReadability: boolean;
    hasPageFix: boolean;
    userCount: number;
    hasMaxScannedPages: boolean;
    pagesLeft: number;
    hasConstraintsOrExcludes: boolean;
    hasPageAssist: boolean;
    domainCustomer: CustomerInterface | Customer;
}

@Component({
    selector: 'mon-backend-admin-domains-table',
    templateUrl: './table.html',
})
export class BackendAdminDomainsTableComponent extends BaseApiComponent implements OnInit {
    @Input() monDomains: CollectionInterface<Domain>;
    @Input() monShowCustomer: boolean;
    @Input() monCustomer: Customer;
    @Input() monSelectedDomains: Domain[];

    @Output() monGetPageEvent: EventEmitter<void> = new EventEmitter();

    jobs: Job;
    domainsArray: Domain[] = [];
    computedDomainsArray: ComputedDomain[] = [];

    constructor (
        private jobService: JobService,
        private backendDomainsRepo: BackendDomainsRepo,
        private toastService: ToastService,
        private translateService: TranslateService,
        private dialogService: DialogService,
        $location: UrlService,
    ) {
        super($location);
    }

    ngOnInit (): void {
        this.jobs = this.jobService.jobViewModelWrapper();
    }

    ngOnChanges (changes: SimpleChanges): void {
        if (changes.monDomains && changes.monDomains.currentValue) {
            const { currentValue } = changes.monDomains;
            this.monDomains = currentValue;
            this.domainsArray = Array.from(currentValue);
            this.computedDomainsArray = this.domainsArray.map((domain) => {
                const newDomain = cloneDeep(domain) as ComputedDomain;
                newDomain.hasAccessibility = this.domainHasAccessibility(domain);
                newDomain.hasStatistics = this.domainHasStatistics(domain);
                newDomain.hasReadability = this.domainHasReadability(domain);
                newDomain.hasPageFix = this.domainHasPageFix(domain);
                newDomain.userCount = this.getUserCount(domain);
                newDomain.hasMaxScannedPages = this.hasMaxScannedPages(domain);
                newDomain.pagesLeft = this.getPagesLeft(domain);
                newDomain.hasConstraintsOrExcludes = this.hasConstraintsOrExcludes(domain);
                newDomain.hasPageAssist = this.domainHasPageAssist(domain);
                newDomain.domainCustomer = this.getCustomer(domain);
                return newDomain;
            });
        }
    }

    getPage (): void {
        this.monGetPageEvent.emit();
    }

    onEditDomain (domain: Domain): void {
        const dialogRef = this.dialogService.open(FormDomainComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb: (forceUpdate: boolean) => {
                if (forceUpdate) {
                    this.getPage();
                }
            },
            formConfirmPromptParentSelector: '#common-dialog-wrapper',
        });
        dialogRef.componentInstance.monDomain = domain;
        dialogRef.componentInstance.monIsBackendAdmin = true;
    }

    onRewriteDomainURL (domain: Domain): void {
        const dialogRef = this.dialogService.open(FormDomainURLRewriteComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb: (job: JobInterface) => {
                if (!job) {
                    return;
                }
                this.jobService.watchJob(job, JOB_TYPES.DOMAIN, domain.id).subscribe((newJob) => {
                    this.jobs.watchJobHandle(newJob, domain.id);
                });
                this.getPage();
            },
        });

        dialogRef.componentInstance.domain = domain;
    }

    cloneDomainObject (domain: Domain): Domain {
        const cloned = cloneDeep(domain);
        cloned.domain_users.forEach((user) => {
            delete user.id;
        });
        cloned.link_excludes.forEach((exclude) => {
            delete exclude.id;
        });
        cloned.path_constraints.forEach((constraint) => {
            delete constraint.id;
        });

        delete cloned.crawl_history;
        delete cloned.updated_at;
        delete cloned.created_at;
        delete cloned.crawl_status;
        delete cloned.id;
        delete cloned.url;
        delete cloned.title;
        cloned.domain_groups = [];
        return cloned;
    }

    cloneDomain (domain: Domain): void {
        const dialogRef = this.dialogService.open(FormDomainComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb: () => this.getPage(),
            formConfirmPromptParentSelector: '#common-dialog-wrapper',
        });
        dialogRef.componentInstance.monDomain = this.cloneDomainObject(domain);
        dialogRef.componentInstance.monCustomer = this.monCustomer;
    }

    openPageAssist (domain: Domain): void {
        const dialogRef = this.dialogService.open(FormBackendAdminPageAssistComponent, {
            classes: 'mon-dialog-size-md',
            defaultWrapper: false,
            cb: () => this.getPage(),
        });

        dialogRef.componentInstance.saveToAPI = true;
        dialogRef.componentInstance.domain = domain;
        dialogRef.componentInstance.customer = domain.customer;
    }

    openPageFix (domain: Domain): void {
        const dialogRef = this.dialogService.open(PageFixDomainSettingsComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb: () => this.getPage(),
        });

        dialogRef.componentInstance.saveToAPI = true;
        dialogRef.componentInstance.domain = domain;
        dialogRef.componentInstance.customer = domain.customer;
        dialogRef.componentInstance.isAdmin = true;
    }

    async onRescan (domain: Domain): Promise<void> {
        await this.backendDomainsRepo.rescan(domain.customer_id, domain.id);
        this.toastService.success(this.translateService.getString('Rescan started'));
        this.getPage();
    }

    deleteDomain (result: string): (domain: Domain) => void {
        return (domain: Domain): void => {
            if (!Number(domain.id) || !Number(domain.customer_id)) {
                this.toastService.error(this.translateService.getString('Domain or Customer is not defined properly'));
            }
            if (result === domain.id.toString()) {
                this.backendDomainsRepo.destroy(domain.customer_id, domain.id).then(() => {
                    this.toastService.success(this.translateService.getString('Domain was deleted'));
                    this.getPage();
                });
            } else {
                this.toastService.error(this.translateService.getString('ID was not correct'));
            }
        };
    }

    private domainHasAccessibility (domain: Domain): boolean {
        const customer = this.getCustomer(domain);
        if (customer) {
            return domain.features.accessibility && customer.plan_traits.accessibility;
        }
        return false;
    }

    private domainHasStatistics (domain: Domain): boolean {
        const customer = this.getCustomer(domain);
        if (customer) {
            return domain.features.statistics && customer.plan_traits.statistics;
        }
        return false;
    }

    private domainHasReadability (domain: Domain): boolean {
        const customer = this.getCustomer(domain);
        if (customer) {
            return domain.features.readability_test && customer.plan_traits.readability;
        }
        return false;
    }

    private domainHasPageFix (domain: Domain): boolean {
        const customer = this.getCustomer(domain);
        if (customer) {
            return domain.features.page_fix && customer.plan_traits.page_fix;
        }
        return false;
    }

    private getUserCount (domain: Domain): number {
        return domain.domain_users.reduce((num, user)=> {
            if (user.visible) {
                num++;
            }
            return num;
        }, 0);
    }

    private hasMaxScannedPages (domain: Domain): boolean {
        return (
            !isNaN(domain.scan.max_scanned_pages) &&
            isFinite(domain.scan.max_scanned_pages) &&
            typeof domain.scan.max_scanned_pages === 'number'
        );
    }

    private getPagesLeft (domain: Domain): number {
        const maxScannedPages = domain.scan.max_scanned_pages || 0;
        return (
            maxScannedPages -
                (domain.crawled_pages ? domain.crawled_pages : 0) -
                (domain.crawl_history ? domain.crawl_history.documents_count : 0) || 0
        );
    }

    private hasConstraintsOrExcludes (domain: Domain): boolean {
        return domain.path_constraints.length + domain.link_excludes.length > 0;
    }

    private domainHasPageAssist (domain: Domain): boolean {
        const customer = this.getCustomer(domain);
        if (customer) {
            return domain.features.page_assist && customer.plan_traits.page_assist;
        }
        return false;
    }

    private getCustomer (domain: Domain): CustomerInterface | Customer {
        if (this.monCustomer) {
            return this.monCustomer;
        } else if (domain.customer) {
            return domain.customer;
        }

        return null;
    }
}
