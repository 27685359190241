export function MonEventsServiceFactory() {
  let internalIds = 0;
  const runEvent = function (listeners, params) {
    const promises = [];
    for (let i = 0; i < listeners.length; i++) {
      if (listeners[i] !== undefined) {
        const fn = listeners[i].fn;
        promises.push(fn.apply(fn, params));
      }
    }
    return Promise.all(promises);
  };
  const listeners = {};
  const service = {
    addListener: addListener,
    run: run,
    remove: remove
  };
  function addListener(type, fn) {
    if (listeners[type]) {
      listeners[type].push({
        id: internalIds,
        fn: fn
      });
    } else {
      listeners[type] = [{
        id: internalIds,
        fn: fn
      }];
    }
    internalIds++;
    return internalIds - 1;
  }
  function remove(id) {
    for (const key in listeners) {
      if (listeners.hasOwnProperty(key)) {
        for (let u = 0; u < listeners[key].length; u++) {
          if (listeners[key][u] && id === listeners[key][u].id) {
            listeners[key].splice(u, 1);
          }
        }
      }
    }
  }
  function run(...args) {
    const type = args[0];
    args.shift();
    if (listeners[type]) {
      return runEvent(listeners[type], args);
    }
    return Promise.resolve();
  }
  return service;
}