import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
    ToastService,
    ActiveDialog,
} from '@monsido/angular-shared-components';
import { CustomerService } from '@monsido/services/customer/customer.service';
import { UserService } from '@monsido/services/user/user.service';
import { UserForm } from '@monsido/forms/user/user-form';
import { User } from '@monsido/modules/models/api/user';
import { BaseForm } from '@monsido/forms/base-form';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-form-user',
    templateUrl: 'user.component.html',
})
export class FormUserComponent extends BaseForm implements OnInit, AfterViewInit {
    @Input() user: User;
    @Input() customerId: number;
    saving: boolean;
    formUser: UserForm;

    @ViewChild('userForm', { static: false }) userForm: NgForm;
    constructor (
        private activeDialog: ActiveDialog,
        private toastService: ToastService,
        private translateService: TranslateService,
        private customerService: CustomerService,
        private userService: UserService,
    ) {
        super();
    }

    ngOnInit (): void {
        this.formUser = new UserForm(this.user);
    }

    ngAfterViewInit (): void {
        if (this.userForm) {
            setTimeout(() => this.resetFormState(this.userForm));
            this.activeDialog.setForm(this.userForm);
        }
    }

    submit (): void {
        if (!this.userForm.touched) {
            this.userForm.control.markAsPristine();
            this.close();
            return;
        }
        let promise: Promise<void>;
        this.saving = true;
        this.formUser = this.userService.setSocialMediaParameter(this.formUser);
        const passwordValid = !this.formUser.password || this.formUser.password === this.formUser.password_confirmation;
        if (passwordValid) {
            if (this.formUser.id) {
                promise = this.customerService.updateUser(this.customerId, this.formUser);
            } else {
                promise = this.customerService.createUser(this.customerId, this.formUser);
            }

            promise
                .then(() => {
                    this.resetFormState(this.userForm);
                    this.toastService.success(this.translateService.getString('User saved'));
                    this.close(true);
                })
                .finally(() => {
                    this.saving = false;
                });
        } else {
            this.saving = false;
            this.toastService.error(this.translateService.getString('Password is not valid'));
        }
    }

    close (forceUpdate?: boolean): void {
        this.activeDialog.close(forceUpdate);
    }
}
