import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { Domain } from '@monsido/modules/models/api/interfaces/crawl-queue.interface';
import { UrlService } from '@uirouter/core';
import { HttpHeaders } from '@angular/common/http';
import { ParamService } from '@monsido/core/param/param.service';
import { DomainRepo } from 'app/services/api/admin/domain_repo';
import { LayoutModule, TableModule, TableSearchModule } from '@monsido/angular-shared-components';
import { DomainsTableComponent } from '../domains-table/domains-table.component';
import { TranslateModule } from 'app/modules/translate.module';

@Component({
    selector: 'mon-domains',
    standalone: true,
    imports: [CommonModule, LayoutModule, TableModule, DomainsTableComponent, TableSearchModule, TranslateModule],
    templateUrl: './domains.component.html',
    styleUrls: ['./domains.component.scss'],
})
export class DomainsComponent extends BaseApiComponent implements OnInit, OnDestroy {
    domains: Domain[];
    timerPromise: NodeJS.Timeout;

    constructor (protected urlService: UrlService, private paramService: ParamService, private domainRepo: DomainRepo) {
        super(urlService);
    }

    ngOnInit (): void {
        this.domains = [];
        this.loadLocations();
        this.startAutoReload();
    }

    ngOnDestroy (): void {
        this.destroyTimer();
    }


    destroyTimer (): void {
        clearTimeout(this.timerPromise);
    }

    startAutoReload (): void {
        this.timerPromise = setTimeout(() => {
            this.getDomains(false);
            this.startAutoReload();
        }, 30000);
    }

    getPage (searchText: string = null): void {
        if (searchText != null) {
            this.search = searchText;
        }
        this.paramService.setParams({
            search: this.search,
            page_size: this.pageSize,
            page: this.page,
        });
        this.getDomains(true);
    }

    getDomains (returnPromise: boolean): void {
        const params = {
            page: this.page,
            page_size: this.pageSize,
            search: this.search,
        };
        if (returnPromise !== false) {
            this.loading = true;
        }
        this.domainRepo.getAll(params, new HttpHeaders({ noParseSearch: 'true' })).then(
            (data: Domain[]) => {
                this.domains = data;
                this.loading = false;
            }, (res) => {
                if (returnPromise !== false) {
                    this.loading = res.status === -1 && res.xhrStatus === 'abort';
                }
            });
    }

}
