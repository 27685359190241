import { NgModule } from '@angular/core';
import { StorageModule } from '@monsido/core/storage/storage.module';
import { SessionModule } from '@monsido/core/session/session.module';
import { PipesModule } from '@monsido/core/pipes/pipes.module';
import { DialogModule, ToastModule } from '@monsido/angular-shared-components';
import { MonsidoDirectiveModule } from 'app/directives/directives.module';

@NgModule({
    providers: [],
    imports: [StorageModule, SessionModule, PipesModule, ToastModule, DialogModule, MonsidoDirectiveModule],
    exports: [StorageModule, SessionModule, PipesModule, DialogModule],
    declarations: [],
})
export class CoreModule {}
