import { Injectable, OnDestroy } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { Agreement } from '@monsido/modules/models/api/agreement';
import { AccountRepo } from '@monsido/modules/endpoints/api/admin/account.repo';
import { Account } from '@monsido/modules/models/api/account';
import { AgreementsRepo } from '@monsido/modules/endpoints/api/admin/agreements.repo';
import { HttpHeaders } from '@angular/common/http';
import { EnvInterface } from '../interface/env.interface';
import { EventsTypeEnum } from '@monsido/services/mon-event/events.type';

@Injectable()
export class CoreAgreementService implements OnDestroy {
    private envAgreementsMap: Map<string, Agreement[]> = new Map<string, Agreement[]>();
    private listenersId: number[] = [];

    constructor (
        private agreementsRepo: AgreementsRepo,
        private sessionService: SessionService,
        private monEventService: MonEventService,
        private accountRepo: AccountRepo,
    ) {
        this.listenersId.push(this.monEventService.addListener(EventsTypeEnum.loadResellerAgreements, (urls: string[]) => {
            this.loadResellerAgreements(urls);
        }));
    }

    ngOnDestroy (): void {
        this.listenersId.forEach(id => this.monEventService.remove(id));
    }

    initAgreements (): void {
        if (this.sessionService.agreements === null) {
            this.sessionService.resetAgreements();
        }
    }

    setAgreements (agreements: Agreement[]): void {
        this.initAgreements();
        this.sessionService.agreements.next(Object.assign([], agreements));
    }

    clearAgreement (): Promise<void> {
        this.setAgreements([]);
        this.sessionService.account = null;
        this.sessionService.agreement = null;
        return this.monEventService.run('clearAgreement') as Promise<void>;
    }

    setAgreement (agreement: Agreement): Promise<Account> {
        this.sessionService.agreement = agreement;
        return this.monEventService.run('setAgreement', agreement, this.sessionService.me).then(() => {
            return this.getAccount();
        });
    }

    getAccount (): Promise<Account> {
        return this.accountRepo.get().then((account: Account) => {
            this.sessionService.account = account;
            return this.monEventService
                .run('setAccount', account, this.sessionService.agreement, this.sessionService.me)
                .then(() => {
                    return account;
                });
        });
    }

    async getResellerAgreementsByEnvURL (envURL: string): Promise<Agreement[]> {
        const agreements = (await this.agreementsRepo
            .getAll({ headers: new HttpHeaders({ noGlobal: 'true' }) }, envURL + '/reseller/api/')
            .catch(() => {})) || [];
        this.envAgreementsMap.set(envURL, agreements);
        return agreements;
    }

    getAgreementsByEnvURLCached (envURL: string): Agreement[] {
        return this.envAgreementsMap.get(envURL) || [];
    }

    async setAdminEnv (env: EnvInterface): Promise<void> {
        await this.clearAgreement();
        await this.sessionService.setSelectedApi(env.url);
    }

    async setResellerEnv (env: EnvInterface, agreement: Agreement): Promise<void> {
        await this.clearAgreement();
        await this.sessionService.setSelectedApi(env.url);
        await this.setAgreement(agreement);
    }

    private async loadResellerAgreements (urls: string[]): Promise<void> {
        for (const url of urls) {
            await this.getResellerAgreementsByEnvURL(url);
        }
        this.monEventService.run(EventsTypeEnum.loadResellerAgreementsDone);
    }
}
