import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import {
    ActiveDialog,
    LayoutModule,
    MonPromptModule,
    MonPromptService,
    ToastService,
} from '@monsido/angular-shared-components';
import { FormCustomerUserBasicComponent } from './steps/basic/form-customer-user-basic.component';
import {
    FormCustomerUserDomainsComponent,
} from './steps/form-customer-user-domains/form-customer-user-domains.component';
import { DomainModel } from '../import-users/steps/domains/domain-import/domain-model';
import { CustomerRepo } from '../../services/api/admin/customer-repo';
import { HttpHeaders } from '@angular/common/http';
import { GlobalHelperService } from '@monsido/services/global-helper/global-helper.service';
import { UserSocialMedia } from '@monsido/modules/models/api/interfaces/user.interface';
import { UserForm } from '@monsido/forms/user/user-form';
import { User } from '@monsido/modules/models/api/user';
import { MonIconsPipe } from '../../filters/mon-icons.pipe';
import { BaseForm } from '@monsido/forms/base-form';
import { TranslateModule } from 'app/modules/translate.module';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-form-customer-user',
    templateUrl: 'form-customer-user.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        LayoutModule,
        FormCustomerUserBasicComponent,
        FormCustomerUserDomainsComponent,
        TranslateModule,
        MonPromptModule,
        MonIconsPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class FormCustomerUserComponent extends BaseForm implements OnInit, AfterViewInit {
    @Input() user: UserForm | null = null;
    @Input() customerId: number;

    @ViewChild('customerUserForm') customerUserForm: NgForm;

    loading = false;
    saving = false;
    domains: DomainModel[] = [];

    constructor (
        private customerRepo: CustomerRepo,
        private globalHelperService: GlobalHelperService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private activeDialog: ActiveDialog,
        private monPromptService: MonPromptService,
    ) {
        super();
    }

    ngAfterViewInit (): void {
        if (this.customerUserForm) {
            this.activeDialog.setForm(this.customerUserForm);
            setTimeout(() => {
                this.resetFormState(this.customerUserForm);
            });
        }
    }

    ngOnInit (): void {
        if (!this.user) {
            this.user = new UserForm({} as User);
            this.user.customer_admin = false;
        }
    }

    async submit (): Promise<void> {
        this.saving = true;

        if (this.customerUserForm.valid) {
            this.customerUserForm.control.markAsPristine();
        }

        this.setDomainUsers();
        this.setDomainGroupMembers();
        this.setSocialMediaParameter();

        const passwordValid = !this.user.password || this.user.password === this.user.password_confirmation;

        if (passwordValid) {
            try {
                if (this.user.id) {
                    await this.customerRepo.updateUser(this.customerId, this.user);
                } else {
                    await this.customerRepo.createUser(this.customerId, this.user, new HttpHeaders({}));
                }

                this.close();
                this.toastService.success(this.translateService.getString('User saved'));
            } finally {
                this.saving = false;
            }
        } else {
            this.saving = false;
            await this.monPromptService.alert(this.translateService.getString('Password is not valid'));
        }

    }

    private setDomainUsers (): void {
        if (Array.isArray(this.user.domain_users)) {
            this.user.domain_users = this.user.domain_users.map((domain) => {
                domain.visible = Boolean(this.user.customer_admin || domain.visible);
                domain.send_report = Boolean(domain.visible && domain.send_report);
                return domain;
            });
        }
    }

    private setDomainGroupMembers (): void {
        if (Array.isArray(this.user.domain_group_members)) {
            this.user.domain_group_members = this.user.domain_group_members.map((domain) => {
                domain.visible = Boolean(this.user.customer_admin || domain.visible);
                domain.send_report = Boolean(domain.visible && domain.send_report);
                return domain;
            });
        }
    }

    private setSocialMediaParameter (): void {
        // Remove social_media parameter if not valid
        if (this.globalHelperService.isObject(this.user.social_media)) {
            // If an object
            Object.keys(this.user.social_media) // Remove all undefined parameters
                .forEach((media: keyof UserSocialMedia) => {
                    if (this.globalHelperService.isUndefined(this.user.social_media[media])) {
                        delete this.user.social_media[media];
                    }
                });

            if (Object.keys(this.user.social_media).length === 0) {
                // Check if any parameters are left
                this.user.social_media = null;
            }
        } else {
            // Remove social_media parameter if not an object
            this.user.social_media = null;
        }
    }

    private close (): void {
        this.activeDialog.close(this.user);
    }
}
