export const CRAWL = {
    STATE: {
        STARTED: 'STARTED',
        RETRY: 'RETRY',
        ON_HOLD: 'ON-HOLD',
        QUEUED: 'QUEUED',
        SUPPORT: 'SUPPORT',
        COOKIE_SCAN: 'COOKIE_SCAN',
        ACCESSIBILITY_PROBLEM: 'ACCESSIBILITY_PROBLEM',
        BACKOFF: 'BACKOFF',
    },

    TAB: {
        ALL: 'all',
        ACTIVE: 'active',
        ON_HOLD: 'on-hold',
        RETRY: 'retry',
        QUEUED: 'queued',
        SUPPORT: 'support',
        ACCESSIBILITY_PROBLEM: 'accessibility_problem',
    },
};
