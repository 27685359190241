import { Component, Input, OnInit, ChangeDetectorRef, AfterViewInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastService, ActiveDialog } from '@monsido/angular-shared-components';
import { DataPrivacyCheckInterface } from '@monsido/modules/models/api/interfaces/data-privacy-check.interface';
import { DATA_PRIVACY_CHECK } from '@monsido/core/constants/data-privacy-check.constant';
import { BackendDataPrivacyCheckRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-data-privacy-check.repo';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-form-backend-admin-data-privacy-check',
    templateUrl: 'data-privacy-check.html',
})
export class FormBackendAdminDataPrivacyCheckComponent implements OnInit, AfterViewInit {
    @Input() check: DataPrivacyCheckInterface;
    @ViewChild('dataPrivacyForm', { static: false }) dataPrivacyForm: NgForm;
    onSave: boolean = false;
    groups: Array<Record<string, string>>;
    priorityLevels: Array<Record<string, string | number>>;
    regulations: Array<Record<string, string>>;
    regions: Array<Record<string, string>>;
    form: NgForm;
    summerNoteConfig: Record<string, string> = {
        height: '300px',
    };
    constructor (
        private activeDialog: ActiveDialog,
        private toastService: ToastService,
        private translateService: TranslateService,
        private backendDataPrivacyCheckRepo: BackendDataPrivacyCheckRepo,
        private cdref: ChangeDetectorRef,
    ) {}

    ngOnInit (): void {
        this.groups = DATA_PRIVACY_CHECK.GROUPS;
        this.priorityLevels = DATA_PRIVACY_CHECK.PRIORITY_LEVELS;
        this.regulations = DATA_PRIVACY_CHECK.REGULATIONS;
        this.regions = DATA_PRIVACY_CHECK.REGIONS;
    }

    // TODO: Find an alternative, instead of relying on lifecycle hooks. https://zube.io/monsido/monsido/c/21921
    ngAfterContentChecked (): void {
        this.cdref.detectChanges();
    }

    ngAfterViewInit (): void {
        if (this.dataPrivacyForm) {
            this.activeDialog.setForm(this.dataPrivacyForm);
        }
    }

    submit (): void {
        if (!this.dataPrivacyForm.touched) {
            this.dataPrivacyForm.control.markAsPristine();
            this.close();
            return;
        }
        this.dataPrivacyForm.control.markAsPristine();

        this.onSave = true;
        this.backendDataPrivacyCheckRepo.updateCheck(this.check).then(
            () => {
                this.toastService.success(this.translateService.getString('Data Privacy Check saved'));
                this.close(true);
            },
            () => {
                this.onSave = false;
            },
        );
    }

    canSave (): string {
        return this.check.group && this.check.description && this.check.how_to_mitigate;
    }

    close (forceUpdate?: boolean): void {
        this.activeDialog.close(forceUpdate);
    }
}
