import { Ng2StateDeclaration } from '@uirouter/angular';
import { SearchUsersPageComponent } from '@monsido/pages/backend-admin/support/search-users/search-users.component';

export const SEARCH_USERS_STATES: Ng2StateDeclaration[] = [
    {
        name: 'base.backend_admin.support.users-search',
        url: '/users-search?search',
        views: {
            '^.^.^.$default': {
                component: SearchUsersPageComponent,
            },
        },
        dynamic: true,
    },
];
