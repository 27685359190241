import { Injectable } from '@angular/core';
import { MonPromptService } from '@monsido/angular-shared-components';
import { BackendDomainsRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-domains.repo';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Customer } from '@monsido/modules/models/api/customer';
import { Domain } from '@monsido/modules/models/api/domain';
import { cloneDeep } from 'lodash';
import {
    AdditionalInternalUrls,
    BasicAuthLoginType,
    CustomMultistepLoginType,
    DomainScanLogin,
    FormLoginType,
    LinkExcludes,
    Office365LoginType,
    PageAssistSettings,
    PathConstraints,
} from 'types/domain';
import { HttpHeaders } from '@angular/common/http';

export type StepType = 1 | 2 | 3 | 4;

@Injectable({
    providedIn: 'root',
})
export class ImportDomainsService {
    private errors: string[] = [];
    private step: BehaviorSubject<StepType> = new BehaviorSubject<StepType>(1);
    private importCountSubject = new Subject<number>();
    private importCount: number = 0;
    importCount$: Observable<number> = this.importCountSubject.asObservable();
    constructor (private monPromptService: MonPromptService, private domainRepo: BackendDomainsRepo) {}

    setStep (step: StepType): void {
        this.step.next(step);
    }

    getStepObs (): Observable<StepType> {
        return this.step.asObservable();
    }

    getCurrentStep (): StepType {
        return this.step.getValue();
    }

    resetSteps (): void {
        this.step.next(1);
    }

    importDomains (domains: Pick<Domain, 'url' | 'title'>[], settings: Domain, customer: Customer): Promise<void> {
        return this.setupDomains(domains, settings, customer)
            .finally(() => {
                if (this.errors.length) {
                    this.monPromptService.alert(this.errors.join('\r\n \r\n'));
                }
                this.importCount = 0;
                this.importCountSubject.next(this.importCount);
                return Promise.resolve();
            });
    }


    async setupDomains (domains: Pick<Domain, 'url' | 'title'>[], settings: Domain, customer: Customer): Promise<void> {
        for (const domain of domains) {
            if (domain.url !== undefined && domain.title !== undefined) {  
                await this.submit(domain.title, domain.url, settings, customer).catch((err) => {
                    this.errors.push('Error importing ' + domain.url + ' ' + this.getErrorString(err.error.errors) + '\r\n\r\n');
                });
            }
            this.importCountSubject.next(this.importCount++); // Increment and emit new count
        }
    }

    private getErrorString (errors: Record<string, unknown[]>): string {
        let errorString = '';
        for (const [key, value] of Object.entries(errors)) {
            errorString += `- ${key}: `;

            if (Array.isArray(value)) {
                errorString += value[0] + '\n'; // Get first error message
            } else if (typeof value === 'object') {
                errorString += this.getErrorString(value) + '\n'; // Recursively handle nested errors
            } else {
                errorString += `${value}\n`; // Handle non-array or object values
            }
        }

        return errorString.trim();
    }

    async submit (title: string, url: string, settings: Domain, customer: Customer): Promise<Domain> {
        const domain = cloneDeep(settings);
        domain.url = this.getValidUrl(url);
        domain.title = title;
        const { day, time } = domain.getScanDayTimeUTC();
        domain.scan.time = time;
        domain.scan.day = day;
        if (domain.link_excludes) {
            domain.link_excludes = this.clearEmptyLinkExcludes(domain.link_excludes);
        }
        if (domain.path_constraints) {
            domain.path_constraints = this.clearEmptyPathConstraints(domain.path_constraints);
        }
        domain.additional_internal_urls = this.clearEmptyInternalUrls(domain.additional_internal_urls);
        domain.page_assist_settings = this.setupPageAssistSettings(domain.page_assist_settings, customer);
        domain.language = !domain.language ? null : domain.language;
        domain.scan.scan_interval = domain?.scan?.scan_interval > 0 ? domain.scan.scan_interval : null;
        domain.scan.login = this.setupScanLogin(domain.scan.login);
        domain.customer_id = customer.id;
        const headers = new HttpHeaders({ noGlobal: 'true' });
        return this.domainRepo.create(domain.customer_id, domain, headers);
    }

    private getValidUrl (url: string): string {
        let newUrl = window.decodeURIComponent(url);
        newUrl = newUrl.trim().replace(/\s/g, '');

        if (/^(:\/\/)/.test(newUrl)) {
            return 'https' + newUrl;
        }
        if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
            return 'https://' + newUrl;
        }
        return newUrl;
    }

    private setupScanLogin (login: DomainScanLogin): DomainScanLogin | null {
        if (!login || login.type === 'none') {
            return null;
        } else {
            switch (login.type) {
                case 'custom_multistep':
                    return {
                        type: login.type,
                        verify: login.verify,
                        [login.type]: login[login.type] as CustomMultistepLoginType['custom_multistep'],
                    };
                case 'form':
                    return {
                        type: login.type,
                        verify: login.verify,
                        [login.type]: login[login.type] as FormLoginType['form'],
                    };
                case 'office365':
                    return {
                        type: login.type,
                        [login.type]: login[login.type] as Office365LoginType['office365'],
                    };
                case 'basic_auth':
                    return {
                        type: login.type,
                        [login.type]: login[login.type] as BasicAuthLoginType['basic_auth'],
                    };
                default:
                    return null;
            }
        }
    }

    private clearEmptyInternalUrls (internalUrls: AdditionalInternalUrls[]): AdditionalInternalUrls[] {
        return internalUrls.filter((url) => {
            return typeof url.value === 'string' && url.value.trim().length > 0;
        });
    }

    private clearEmptyPathConstraints (constraints: PathConstraints[]): PathConstraints[] {
        return constraints.filter((constraint) => {
            return constraint?.constraint?.length > 0;
        });
    }

    private clearEmptyLinkExcludes (excludes: LinkExcludes[]): LinkExcludes[] {
        return excludes.filter((exclude) => {
            return exclude?.regex?.length > 0;
        });
    }

    private setupPageAssistSettings (settings: PageAssistSettings | null, customer: Customer): PageAssistSettings | null {
        if (customer.plan_traits.page_assist && settings != null) {
            settings.main = '';
            settings.elements_to_hide = settings.elements_to_hide.filter(Boolean);

            settings.navigation = settings.navigation
                .map((navigation) => {
                    navigation.selectors = navigation.selectors.filter(Boolean);
                    return navigation;
                })
                .filter((navigation) => {
                    return (
                        typeof navigation.mainselector === 'string' && navigation.mainselector.length > 0 && navigation.selectors.length > 0
                    );
                });
        }
        return settings;
    }
}
