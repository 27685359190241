/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { ResellerAdminUsersRepo } from '@monsido/modules/endpoints/api/admin/reseller-admin-users.repo';
import { User } from '@monsido/modules/models/api/user';
import { FormResellerAdminUserComponent } from '@monsido/forms/reseller-admin/user/user.component';
import { HttpParams } from '@angular/common/http';
import { DialogService, ToastService } from '@monsido/angular-shared-components';
import { TranslateService } from 'app/services/translate/translate.service';
@Injectable()
export class ResellerAdminUsersService {
    constructor (
        private resellerAdminUsersRepo: ResellerAdminUsersRepo,
        private toastService: ToastService,
        private translateService: TranslateService,
        private dialogService: DialogService,
    ) {}

    getAll (params: HttpParams): Promise<User[]> {
        return this.resellerAdminUsersRepo.getAll(params);
    }

    deleteUser (userId: number): Promise<void> {
        return this.resellerAdminUsersRepo.delete(userId).then(() => {
            this.toastService.success(this.translateService.getString('User deleted'));
        });
    }

    updateUser (userId: number, user: User): Promise<User> {
        return this.resellerAdminUsersRepo.update(userId, user);
    }

    editUser (user: User, cb: (...args: unknown[]) => void): void {
        const dialogRef = this.dialogService.open(FormResellerAdminUserComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb,
        });

        dialogRef.componentInstance.user = Object.assign({}, user);
    }

    createUser (cb: (...args: unknown[]) => void): void {
        // eslint-disable-next-line no-unused-vars
        const dialogRef = this.dialogService.open(FormResellerAdminUserComponent, {
            classes: 'mon-dialog-size-sm',
            defaultWrapper: false,
            cb,
        });
    }
}
