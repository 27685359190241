import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

import 'bootbox/bootbox.js';

import { MonPromptService } from '@monsido/angular-shared-components';

@Directive({ selector: '[monConfirm]' })
export class ConfirmDirective {
    @Input() monConfirm: string;
    // TODO remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() monConfirmAction: EventEmitter<any> = new EventEmitter<any>();

    constructor (private monPromptService: MonPromptService) {}

    @HostListener('click', ['$event'])
    go (event: MouseEvent): Promise<void> {
        event.preventDefault();
        return this.monPromptService.confirm(this.monConfirm).then(
            () => {
                this.monConfirmAction.emit();
            },
            () => {},
        );
    }
}
