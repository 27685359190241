import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule, TabsModule } from '@monsido/angular-shared-components';
import { ResellersRepo } from 'app/services/api/backend_admin/resellers-repo';
import { UIRouterGlobals } from '@uirouter/core';
import { BackendAdminResellerUserTabComponent } from '../backend-admin-reseller-user-tab/backend-admin-reseller-user-tab.component';
import { ResellerDomainsTabComponent } from '../reseller-domains-tab/reseller-domains-tab.component';
import { PartnerCustomers } from '../partner-customers/partner-customers.component';
import { MonIconsPipe } from 'app/filters/mon-icons.pipe';
import { Reseller } from '@monsido/modules/models/api/interfaces/user.interface';
import { TranslateModule } from 'app/modules/translate.module';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-backend-admin-reseller-show',
    standalone: true,
    imports: [
        CommonModule, LayoutModule, TranslateModule, BackendAdminResellerUserTabComponent,
        ResellerDomainsTabComponent, PartnerCustomers, MonIconsPipe, TabsModule,
    ],
    templateUrl: './backend-admin-reseller-show.component.html',
    styleUrls: ['./backend-admin-reseller-show.component.scss'],
})
export class BackendAdminResellerShowComponent implements OnInit {
    tabs: Record<string, string>[];
    activeTab: string;
    reseller: Reseller;

    constructor (
        private translateService: TranslateService,
        private resellersRepo: ResellersRepo,
        private uiRouterGlobals: UIRouterGlobals,
    ) {}

    async ngOnInit (): Promise<void> {
        await this.getReseller();
        this.tabs = [
            {
                name: this.translateService.getString('Users'),
                faIcon: 'fas fa-users',
                value: 'users',
            },
            {
                name: this.translateService.getString('Domains'),
                faIcon: 'fas fa-globe',
                value: 'domains',
            },
            {
                name: this.translateService.getString('Customers'),
                faIcon: 'fas fa-building',
                value: 'customers',
            },
        ];
        this.activeTab = 'users';
    }

    async getReseller (): Promise<void> {
        await this.resellersRepo.get(this.uiRouterGlobals.params.reseller_id, undefined, undefined).then((data) => {
            this.reseller = data;
        },
        () => { });
    }

    onTabChange (tab: string): void {
        this.activeTab = tab;
    }
}
