import { Component, HostListener, OnDestroy } from '@angular/core';
import { HeatmapsService } from '@monsido/pages/backend-admin/support/heatmaps/heatmaps.service';
import { Domain } from '@monsido/modules/models/api/domain';
import { SummaryParamsInterface } from '@monsido/pages/backend-admin/support/heatmaps/summary-params.interface';
import { HeatmapSummaryInterface } from '@monsido/modules/models/api/interfaces/heatmap-summary.interface';
import { Subscription } from 'rxjs';
import { MonDatePickerCustom, MonDatePickerMain } from '@monsido/angular-shared-components';

if (!customElements.get('mon-date-picker-custom')) {
    customElements.define('mon-date-picker-custom', MonDatePickerCustom);
}
if (!customElements.get('mon-date-picker-main')) {
    customElements.define('mon-date-picker-main', MonDatePickerMain);
}
interface Date {
    startDate: globalThis.Date;
    endDate: globalThis.Date;
}
@Component({
    selector: 'support-heatmaps-component',
    templateUrl: 'heatmaps.html',
    styleUrls: ['heatmaps.scss'],
})
export class HeatmapsComponent implements OnDestroy {
    searching: boolean = false;
    url: string;
    domain_id: number;
    date: Date;
    loading: boolean = false;
    loadingDomains = true;
    domains: Domain[] = [];
    heatmaps: HeatmapSummaryInterface[] = [];

    latestRequest: Subscription;
    fromDate: globalThis.Date;
    toDate: globalThis.Date;

    constructor (private heatmapService: HeatmapsService) {
        const today = new Date();
        this.date = {
            startDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 8),
            endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1) 
        };
    }
    ngOnDestroy (): void {
        if (this.latestRequest && !this.latestRequest.closed) {
            this.latestRequest.unsubscribe();
        }
    }

    search (): void {
        this.loading = true;
        const params = {
            to: this.date.endDate.toISOString().split('T')[0],
            from: this.date.startDate.toISOString().split('T')[0],
        } as SummaryParamsInterface;
        
        if (this.url) {
            params.url = this.url;
        }
        const domain = this.domains[0];
        if (domain?.customer_id && domain.id) {
            this.heatmapService
                .getSummaryUrl(domain.customer_id, domain.id, params)
                .then((data: HeatmapSummaryInterface[]) => {
                    this.heatmaps = data;
                })
                .finally(() => {
                    this.loading = false;
                });
        } else {
            this.loading = false;
        }
    }

    @HostListener('monDatepickerDatesUpdate', ['$event'])
    onDatepickerMainUpdate(e: CustomEvent<{ dateFrom: string, dateTo: string }>): void {
        this.date.startDate = new Date(e.detail.dateFrom);
        this.date.endDate = new Date(e.detail.dateTo);
    }

    onDomainIdUpdate (): void {
        if (this.latestRequest && !this.latestRequest.closed) {
            this.latestRequest.unsubscribe();
        }

        this.loadingDomains = true;
        this.latestRequest = this.heatmapService.getDomains$(this.domain_id?.toString()).subscribe((domains: Domain[]) => {
            this.domains = domains;
            this.loadingDomains = false;
            this.latestRequest.unsubscribe();
        });
    }

    canSearch (): boolean {
        return this.domains.length === 1;
    }
}
