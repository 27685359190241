import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveDialog, LayoutModule, MonPromptService } from '@monsido/angular-shared-components';
import { CustomerRepo } from 'app/services/api/admin/customer-repo';
import { LANGUAGE_CONSTANT } from '@monsido/core/constants/languages.constant';
import { UnsavedUser } from '@monsido/modules/models/api/interfaces/user.interface';
import { HttpHeaders } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { FormUserUploadComponent } from './steps/form-user-upload/form-user-upload.component';
import { FormUserImportSetupComponent, UserImportCSVData, UserImportSelectedModel } from './steps/setup/setup.component';
import { FormUserImportDomainsComponent } from './steps/domains/domains.component';
import { UserImportConfirmComponent } from './steps/confirm/user-import-confirm/user-import-confirm.component';
import { TranslateModule } from 'app/modules/translate.module';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-form-import-users',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        LayoutModule,
        TranslateModule,
        FormUserUploadComponent,
        FormUserImportSetupComponent,
        FormUserImportDomainsComponent,
        UserImportConfirmComponent,
    ],
    templateUrl: './import-users.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormImportUsersComponent {
    @Input() customerId: number;

    step = 1;
    saving = false;
    data: UserImportCSVData;
    selected: UserImportSelectedModel = {};

    constructor (
        private activeDialog: ActiveDialog,
        private translateService: TranslateService,
        private customerRepo: CustomerRepo,
        private promptService: MonPromptService,
    ) {}

    submit (): void {
        let chain: Promise<unknown> = Promise.resolve();
        const unsubmittedUsers: UnsavedUser[] = [];

        this.saving = true;

        this.data.data.slice(1).forEach(value => {
            const user: UnsavedUser = {
                email: value[this.selected.emailIndex],
                first_name: value[this.selected.firstNameIndex],
                last_name: value[this.selected.lastNameIndex],
                locale: LANGUAGE_CONSTANT.en.code,
                customer_admin: value[this.selected.isAdminIndex]?.toLowerCase() === 'yes',
            };

            user.domain_users = this.selected.domains?.map(domain => ({
                ...domain,
                visible: user.customer_admin || domain.visible,
                send_report: domain.visible && domain.send_report,
            }));

            user.domain_group_members = this.selected.domainGroups?.map(group => ({
                ...group,
                visible: user.customer_admin || group.visible,
                send_report: group.visible && group.send_report,
            }));

            const pwd = value[this.selected.passwordIndex];

            if (pwd) {
                user.password = pwd;
                user.password_confirmation = pwd;
            }

            if (user.email) {
                chain = chain.then(() => {
                    return this.customerRepo
                        .createUser(
                            this.customerId,
                            user,
                            new HttpHeaders({
                                noGlobal: 'true',
                            }),
                        )
                        .then(() => {}, () => unsubmittedUsers.push(user));
                }, () => {});
            }
        });

        chain
            .then(() => {
                const options = {
                    message: ' ',
                    title: this.translateService.getString('All users have been submitted'),
                    buttons: [{
                        label: this.translateService.getString('Ok'),
                        className: 'btn-primary',
                        callback: (): void => {},
                    }],
                };

                if (unsubmittedUsers.length > 0) {
                    const unableToCreate = this.translateService.getString('Unable to create the following users:');
                    let message = `<p><strong>${unableToCreate}</strong></p>`;

                    message = unsubmittedUsers.reduce(
                        (acc: string, user: UnsavedUser) => `${acc}<p>${user.email}</p>`,
                        message,
                    );

                    options.message = message;

                    this.promptService.custom(options);
                } else {
                    this.promptService.alert(options.title);
                }
            }, (): void => {})
            .finally(() => this.close());
    }

    onDataChange (data: UserImportCSVData): void {
        this.data = data;
    }

    onStepChange (step: number): void {
        this.step = step;
    }

    close (): void {
        this.activeDialog.close();
    }
}
