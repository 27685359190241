import { NgModule } from '@angular/core';
import { BackendAdminCustomerShowComponent } from './show/show.component';
import { UIRouterModule } from '@uirouter/angular';
import { BA_CUSTOMERS_STATES } from '@monsido/pages/backend-admin/customers/customers.route';
import { BackendCustomerRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-customer.repo';
import {
    LayoutModule,
    MonAvatarModule,
    TableModule,
    TableSearchModule,
    TabsModule,
    IconsModule,
} from '@monsido/angular-shared-components';
import { BaseLayoutModule } from '@layout/layout.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { JobModule } from '@monsido/modules/job/job.module';
import { CustomerInfoTabComponent } from '@monsido/pages/backend-admin/customers/show/tabs/customer-info-tab/customer-info-tab.component';
import { PipesModule } from '@monsido/core/pipes/pipes.module';
import { BackendAdminUsersTabComponent } from '@monsido/pages/backend-admin/customers/show/tabs/users-tab/users-tab.component';
import { DomainsTabComponent } from './show/tabs/domains-tab/domains-tab.component';
import { BackendAdminDomainsModule } from '@monsido/pages/backend-admin/domains/domains.module';
import { ConfirmModule } from '@monsido/confirm/confirm.module';
import { PromptModule } from '../../../external/prompt/prompt.module';
import { BackendSpellingToolRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-spelling-tool-repo';
import { SudoButtonComponent } from '@layout/buttons/sudo/sudo-button.component';
import { TranslateModule } from 'app/modules/translate.module';

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: BA_CUSTOMERS_STATES }),
        LayoutModule,
        BaseLayoutModule,
        TranslateModule,
        NgbTooltipModule,
        CommonModule,
        JobModule,
        TabsModule,
        PipesModule,
        TableSearchModule,
        TableModule,
        ConfirmModule,
        MonAvatarModule,
        BackendAdminDomainsModule,
        PromptModule,
        IconsModule,
        SudoButtonComponent,
    ],
    declarations: [
        BackendAdminCustomerShowComponent,
        BackendAdminUsersTabComponent,
        CustomerInfoTabComponent,
        DomainsTabComponent,
    ],
    exports: [
        BackendAdminCustomerShowComponent,
        CustomerInfoTabComponent,
    ],
    providers: [
        BackendCustomerRepo,
        BackendSpellingToolRepo,
    ],
})

export class BackendAdminCustomersModule {}
