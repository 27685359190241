import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from 'app/services/translate/translate.service';
@Pipe({
    name: 'monTranslatePlanTraits',
})
export class TranslatePlanTraits implements PipeTransform {
    constructor (private translateService: TranslateService) {}
    transform (word: string): string {
        if (!this.DICTIONARY[word]) {
            return word;
        }
        return this.DICTIONARY[word];
    }

    DICTIONARY: Record<string, string> = {
        accessibility: this.translateService.getString('Accessibility'),
        accessibility_closed_caption: this.translateService.getString('Closed Caption'),
        accessibility_summary: this.translateService.getString('Limit Accessibility Module'),
        api_users: this.translateService.getString('API Users'),
        clarity_pdf_accessibility: this.translateService.getString('PDF Clarity scans'),
        closed_caption: this.translateService.getString('Closed Caption'),
        cookie_banner: this.translateService.getString('Consent Manager'),
        crawl_automatically: this.translateService.getString('Can enable automatic scanning'),
        custom_scan_interval: this.translateService.getString('Can change frequency'),
        dashboard_important_notifications: this.translateService.getString('Dashboard important notifications'),
        data_export: this.translateService.getString('Exports'),
        data_protection: this.translateService.getString('Data Privacy'),
        domain_group_statistics: this.translateService.getString('Domain Group Statistics'),
        general_book_training: this.translateService.getString('Book training'),
        general_chat_support: this.translateService.getString('Chat support'),
        general_shortcuts: this.translateService.getString('Shortcuts'),
        heartbeat: this.translateService.getString('Heartbeat'),
        heatmap_click: this.translateService.getString('Click heatmaps'),
        heatmap_movement: this.translateService.getString('Movement heatmaps'),
        heatmap_scroll: this.translateService.getString('Scroll heatmaps'),
        heatmaps_monthly_sessions: this.translateService.getString('Track number of sessions'),
        heatmaps_tracked_number_of_pages: this.translateService.getString('Track number of pages'),
        heatmaps_traffic_percentage: this.translateService.getString('Traffic percentage'),
        history_center_all: this.translateService.getString('History Center'),
        html_scans: this.translateService.getString('Quick Scans'),
        image_metadata: this.translateService.getString('Scan Image Metadata'),
        inventory_all: this.translateService.getString('Inventory'),
        max_domains: this.translateService.getString('Max domains'),
        max_scanned_pages: this.translateService.getString('Max scanned pages'),
        mobile_guidelines: this.translateService.getString('Mobile Accessibility'),
        monsido_score: this.translateService.getString('Vertical Score'),
        multiple_user_agreements: this.translateService.getString('Allow user agreements'),
        nag_for_upgrade: this.translateService.getString('Nag for upgrade (legacy'),
        on_demand_scans: this.translateService.getString('On-demand scans'),
        page_assist: this.translateService.getString('PageAssist'),
        page_details: this.translateService.getString('Page Details'),
        page_fix: this.translateService.getString('PageCorrect'),
        paying: this.translateService.getString('Paying'),
        clarity_pdf_remediation: this.translateService.getString('PDF Remediation'),
        performance_tracking: this.translateService.getString('Performance'),
        performance_tracking_allowed_frequencies: this.translateService.getString('Frequencies'),
        performance_tracking_max_page_profiles: this.translateService.getString('Track max. page profiles'),
        performance_tracking_max_pages: this.translateService.getString('Track max. pages'),
        performance_tracking_max_profiles: this.translateService.getString('Track max. profiles'),
        performance_tracking_on_demand_scan: this.translateService.getString('On-demand Performance scan'),
        policies_all: this.translateService.getString('Policies'),
        policy_bot: this.translateService.getString('AI-Assisted Policy Bot'),
        prioritized_content_all: this.translateService.getString('Prioritized Content'),
        skip_on_hold: this.translateService.getString('Skip On Hold and auto-release all scans'),
        qa_links: this.translateService.getString('Broken links and images'),
        qa_spellcheck: this.translateService.getString('Spellcheck'),
        qa_summary: this.translateService.getString('Limit QA Module'),
        quick_search: this.translateService.getString('Quick Search'),
        readability: this.translateService.getString('Readability'),
        report_center_all: this.translateService.getString('Report Center'),
        scan_interval: this.translateService.getString('Scan frequency'),
        script_setup_guide: this.translateService.getString('Script Setup Guide'),
        seo_all: this.translateService.getString('SEO'),
        single_page_scan_any_url: this.translateService.getString('Single Page Scan (any URL)'),
        source_code_excludes: this.translateService.getString('Source Code Excludes'),
        spell_check_engine_priority: this.translateService.getString('Spellcheck dictionary preference'),
        statistics: this.translateService.getString('Statistics'),
        uptime_intervals: this.translateService.getString('Ping Intervals'),
        advanced_analytics: this.translateService.getString('Analytics (Piwik PRO)'),
    };
}
