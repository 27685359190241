import { NgModule } from '@angular/core';
import { DialogModule, ActiveDialog } from '@monsido/angular-shared-components';
import { CrawlQueueCommonService } from './crawl-queue-common/crawl-queue-common.service';

@NgModule({
    imports: [DialogModule],
    exports: [],
    declarations: [],
    providers: [CrawlQueueCommonService, ActiveDialog],
})
export class CommonServicesModule {}
