import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackendAdminCustomersRepo } from 'app/services/api/backend_admin/backend-admin-customers-repo';
import { BackendPlansRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-plans.repo';
import { IconsModule, LayoutModule, TableModule } from '@monsido/angular-shared-components';
import { ParamService } from '@monsido/core/param/param.service';
import { TransitionService, UrlService } from '@uirouter/core';
import { BaseApiComponent } from '@monsido/core/components/base-api.component';
import { HttpHeaders } from '@angular/common/http';
import { PlainHttpParams } from '@monsido/http/params';
import { Customer } from '@monsido/modules/models/api/customer';
import { BackendAdminCustomersTableComponent } from './table/table.component';
import { TranslateModule } from 'app/modules/translate.module';
import { TranslateService } from 'app/services/translate/translate.service';

type Tab = Record<string, string | number>;

@Component({
    selector: 'mon-backend-admin-customers',
    standalone: true,
    imports: [
        CommonModule,
        IconsModule,
        LayoutModule,
        TranslateModule,
        TableModule,
        BackendAdminCustomersTableComponent,
    ],
    templateUrl: './customers.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackendAdminCustomersComponent extends BaseApiComponent implements OnInit, OnDestroy {
    tabs: Tab[] = [];
    unsubscribeTransition: unknown;

    customers: Customer[];

    constructor (
        urlService: UrlService,
        private customersRepo: BackendAdminCustomersRepo,
        private plansRepo: BackendPlansRepo,
        private translateService: TranslateService,
        private paramService: ParamService,
        private transitionService: TransitionService,
        private cdRef: ChangeDetectorRef,
    ) {
        super(urlService);
    }

    ngOnInit (): void {
        this.urlParams.push(
            {
                name: 'plan_type',
                type: 'string',
                default: null,
                propertyName: 'activeTab',
            },
        );

        this.customers = [];
        this.tabs = this.defaultTabs();
        this.activeTab = String(this.tabs[0].value);

        this.getPlans();
        this.loadLocations();

        this.unsubscribeTransition = this.transitionService.onError(
            { retained: 'base.backend_admin.customers.all' },
            async (transition) => {
                const { search, page, plan_type: planType } = transition.targetState().params();

                if (this.activeTab != planType || this.search != search || this.page != page) {
                    this.activeTab = planType;
                    this.search = search;

                    if (page) {
                        this.page = page;
                    }

                    await this.getCustomers();
                }
            });
    }

    ngOnDestroy (): void {
        if (typeof this.unsubscribeTransition === 'function') {
            this.unsubscribeTransition();
        }
    }

    getPlans (): void {
        const params = {
            page: 1,
            page_size: 20,
        };

        this.plansRepo.getAll(params).then(
            data => {
                const tabs = this.defaultTabs();

                for (let i = 0; i < data.length; i++) {
                    tabs.push({
                        name: data[i].name,
                        value: data[i].id,
                    });
                }

                this.tabs = tabs;
            },
            (): void => {},
        );
    }

    async getPage (): Promise<void> {
        this.paramService.setParams({
            search: this.search,
            page_size: this.pageSize,
            page: this.page,
            plan_type: this.activeTab,
        });

        await this.getCustomers();
    }

    async getCustomers (): Promise<void> {
        const params: PlainHttpParams = {
            page: this.page,
            page_size: this.pageSize,
            search: this.search,
        };

        if (this.activeTab) {
            params['plans[]'] = this.activeTab;
        }

        this.loading = true;

        try {
            this.customers = await this.customersRepo.getAll(
                params,
                new HttpHeaders({ noParseSearch: 'true' }),
            );
        } finally {
            this.loading = false;
            this.cdRef.markForCheck();
        }
    }

    defaultTabs (): Tab[] {
        return [{
            name: this.translateService.getString('All'),
            value: null,
        }];
    }
}
