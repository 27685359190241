import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ApiModule } from '@monsido/modules/endpoints/api/api.module';
import { BaseLayoutModule } from '@layout/layout.module';
import { CommonModule } from '@angular/common';
import { FormsModule as AngularFormModule } from '@angular/forms';
import { HEATMAPS_STATES } from '@monsido/pages/backend-admin/support/heatmaps/heatmaps.states';
import { HeatmapsComponent } from '@monsido/pages/backend-admin/support/heatmaps/heatmaps.component';
import { HeatmapsService } from '@monsido/pages/backend-admin/support/heatmaps/heatmaps.service';
import {
    TableModule,
    FormBuilderInputModule,
    LayoutModule,
    IconsModule,
    FormBuilderValidationModule,
    MonDatePickerModule,
} from '@monsido/angular-shared-components';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from 'app/modules/translate.module';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        AngularFormModule,
        ApiModule,
        BaseLayoutModule,
        CommonModule,
        FormBuilderInputModule,
        FormBuilderValidationModule,
        IconsModule,
        LayoutModule,
        MonDatePickerModule,
        TableModule,
        TranslateModule,
        UIRouterModule.forChild({ states: HEATMAPS_STATES }),
    ],
    exports: [],
    declarations: [HeatmapsComponent],
    providers: [HeatmapsService],
})
export class SupportHeatmapsModule {}
