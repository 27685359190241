import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { ParamModule } from '@monsido/core/param/param.module';
import { ConfirmModule } from '@monsido/confirm/confirm.module';
import { CommonModule } from '@angular/common';
import {
    MonAvatarModule,
    TableModule,
    IconsModule,
    TableSearchModule,
    LayoutModule,
    DialogModule,
} from '@monsido/angular-shared-components';
import { ApiModule } from '@monsido/modules/endpoints/api/api.module';
import { BaseLayoutModule } from '@layout/layout.module';
import { USERS_STATES } from '@monsido/pages/admin/users/users.state';
import { PipesModule } from '@monsido/core/pipes/pipes.module';
import { UsersComponent } from '@monsido/pages/admin/users/users.component';
import { AdminTableUsersComponent } from '@monsido/pages/admin/users/table/table.component';
import { TableService } from '@monsido/pages/admin/users/table/table.service';
import { UsersService } from '@monsido/pages/admin/users/users.service';
import { ServicesModule } from '@monsido/services/services.module';
import { FormsModule } from '@monsido/forms/forms.module';
import { SudoButtonComponent } from '@layout/buttons/sudo/sudo-button.component';
import { TranslateModule } from 'app/modules/translate.module';
@NgModule({
    imports: [
        ParamModule,
        ConfirmModule,
        TranslateModule,
        MonAvatarModule,
        CommonModule,
        TableModule,
        ApiModule,
        BaseLayoutModule,
        UIRouterModule.forChild({ states: USERS_STATES }),
        LayoutModule,
        PipesModule,
        ServicesModule,
        DialogModule,
        FormsModule,
        TableSearchModule,
        IconsModule,
        SudoButtonComponent,
    ],
    exports: [],
    declarations: [UsersComponent, AdminTableUsersComponent],
    providers: [TableService, UsersService],
})
export class UsersModule {}
