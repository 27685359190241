import { Component, Input, OnInit } from '@angular/core';
import {
    ToastService,
    ActiveDialog,
} from '@monsido/angular-shared-components';
import { BackendCustomerRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-customer.repo';
import { BackendSpellingToolRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-spelling-tool-repo';
import { noop } from 'rxjs';
import { LanguageInterface } from '@monsido/modules/models/api/interfaces/language.interface';
import { PlainHttpParams } from '@monsido/http/params';
import { JobInterface } from '@monsido/modules/models/api/interfaces/job.interface';
import { TranslateService } from 'app/services/translate/translate.service';

@Component({
    selector: 'mon-import-dictionary',
    templateUrl: './import-dictionary.component.html',
})
export class FormImportDictionaryComponent implements OnInit {
    @Input() customerId: number;
    @Input() monDialogId: string;

    csv_url: string;
    selectedLanguage: string;
    supportedLanguages: LanguageInterface[];

    constructor (
        private translateService: TranslateService,
        private backendCustomerRepo: BackendCustomerRepo,
        private toastService: ToastService,
        private spellingToolRepo: BackendSpellingToolRepo,
        private activeDialog: ActiveDialog,
    ) {
        this.csv_url = '';
        this.selectedLanguage = '';
        this.supportedLanguages = [];
    }

    ngOnInit (): void {
        this.spellingToolRepo.getAll().then((data) => {
            this.supportedLanguages = data;
        }, noop);
    }

    close (job: JobInterface): void {
        this.activeDialog.close(job);
    }

    submitDictionary (): void {
        let promise: Promise<JobInterface>;
        const dictionary: PlainHttpParams = {
            csv_url: this.csv_url,
        };

        if (this.selectedLanguage) {
            dictionary.language = this.selectedLanguage;
        }

        if (dictionary.csv_url) {
            promise = this.backendCustomerRepo.importDictionary(this.customerId, dictionary);
        }

        promise.then((job: JobInterface) => {
            this.toastService.info(this.translateService.getString('Import Dictionary Job is put in queue'));
            this.close(job);
        }, noop);
    }
}
