import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule as AngularFormModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import {
    TabsModule,
    TableModule,
    FormBuilderInputModule,
    FormBuilderSelectModule,
    FormBuilderCheckboxModule,
    LayoutModule,
    IconsModule,
    FormBuilderValidationModule,
} from '@monsido/angular-shared-components';

import { REQEUST_CHECKER_STATES } from '@monsido/pages/backend-admin/support/request-checker/request-checker.state';
import { RequestCheckerPageComponent } from '@monsido/pages/backend-admin/support/request-checker/request-checker.component';
import { BaseInfoComponent } from '@monsido/pages/backend-admin/support/request-checker/tabs/base-info/base-info.component';
import { RedirectComponent } from '@monsido/pages/backend-admin/support/request-checker/tabs/redirect/redirect.component';
import { AdvanceComponent } from '@monsido/pages/backend-admin/support/request-checker/tabs/advance/advance.component';
import { ArticleComponent } from '@monsido/pages/backend-admin/support/request-checker/tabs/article/article.component';
import { HtmlComponent } from '@monsido/pages/backend-admin/support/request-checker/tabs/html/html.component';
import { ResponseHeadersComponent } from '@monsido/pages/backend-admin/support/request-checker/tabs/response-headers/response-headers.component';
import { CookiesComponent } from '@monsido/pages/backend-admin/support/request-checker/tabs/cookies/cookies.component';
import { LinksComponent } from '@monsido/pages/backend-admin/support/request-checker/tabs/links/links.component';
import { AccessibilityResultComponent } from '@monsido/pages/backend-admin/support/request-checker/tabs/accessibility/accessibility.component';
import { TextsComponent } from './tabs/texts/texts.component';
import { ScanModule } from '../../../../forms/domain/steps/scan/scan.module';
import { ReadabilityComponent } from './tabs/readability/readability.component';
import { TranslateModule } from 'app/modules/translate.module';


@NgModule({
    providers: [],
    declarations: [
        AdvanceComponent,
        ArticleComponent,
        AccessibilityResultComponent,
        BaseInfoComponent,
        CookiesComponent,
        HtmlComponent,
        LinksComponent,
        RedirectComponent,
        RequestCheckerPageComponent,
        ResponseHeadersComponent,
        ReadabilityComponent,
        TextsComponent,
    ],
    imports: [
        TabsModule,
        TableModule,
        AngularFormModule,
        CommonModule,
        FormBuilderInputModule,
        FormBuilderCheckboxModule,
        FormBuilderValidationModule,
        IconsModule,
        LayoutModule,
        TranslateModule,
        UIRouterModule.forChild({ states: REQEUST_CHECKER_STATES }),
        FormBuilderSelectModule,
        ScanModule,
    ],
})
export class RequestCheckerModule {}
