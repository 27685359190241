import { BehaviorSubject } from "rxjs";
import { GettextPluralsService } from "./gettext-plurals.service";
export class TranslateService {
  constructor() {
    this.noContext = '$$noContext';
    this.debug = false;
    this.debugPrefix = '[MISSING]';
    this.showTranslatedMarkers = false;
    this.translatedMarkerPrefix = '[';
    this.translatedMarkerSuffix = ']';
    this.languageStrings = {};
    this.baseLanguage = 'en-us';
    this.currentLanguage = 'en-us';
    this.onLanguageChange = new BehaviorSubject(this.currentLanguage);
    this.gettextPluralsService = new GettextPluralsService();
  }
  /**
   * returning an observable to hide the behaviour subject
   */
  onLanguageChange$() {
    return this.onLanguageChange.asObservable();
  }
  getString(text, args = {}, context) {
    text = text.trim();
    text = this.getStringFormFor(this.currentLanguage, text, 1, context) || this.getStringFormFor(this.baseLanguage, text, 1, context) || this.prefixDebug(text);
    text = args ? this.interpolate(text, args) : text;
    return this.addTranslatedMarkers(text);
  }
  setCurrentLanguage(lang) {
    this.currentLanguage = lang;
    this.broadcastUpdated();
  }
  getPlural(n, text, textPlural, args = {}, context) {
    text = text.trim();
    text = this.getStringFormFor(this.currentLanguage, text, n, context) || this.getStringFormFor(this.baseLanguage, text, n, context) || this.prefixDebug(n === 1 ? text : textPlural);
    if (args) {
      args['$count'] = n;
      text = this.interpolate(text, args);
    }
    return this.addTranslatedMarkers(text);
  }
  interpolate(str, args) {
    if (str && Object.keys(args).length > 0) {
      for (const key in args) {
        if (args.hasOwnProperty(key)) {
          str = str.replace('[[' + key + ']]', args[key]);
        }
      }
    }
    return str;
  }
  getCurrentLanguage() {
    return this.currentLanguage;
  }
  setStrings(language, strings) {
    if (!this.languageStrings[language]) {
      this.languageStrings[language] = {};
    }
    const defaultPlural = this.gettextPluralsService.getPlurals(language, 1);
    for (const mgsid in strings) {
      if (strings.hasOwnProperty(mgsid)) {
        let val = strings[mgsid];
        if (typeof val === 'string' || Array.isArray(val)) {
          // No context, wrap it in $$noContext.
          const obj = {};
          obj[this.noContext] = val;
          val = obj;
        }
        if (!this.languageStrings[language][mgsid]) {
          this.languageStrings[language][mgsid] = {};
        }
        for (const context in val) {
          if (val.hasOwnProperty(context)) {
            const str = val[context];
            if (!Array.isArray(str)) {
              // Expand single strings
              this.languageStrings[language][mgsid][context] = [];
              this.languageStrings[language][mgsid][context][defaultPlural] = str;
            } else {
              this.languageStrings[language][mgsid][context] = str;
            }
          }
        }
      }
    }
    this.broadcastUpdated();
  }
  addTranslatedMarkers(str) {
    if (this.showTranslatedMarkers) {
      return this.translatedMarkerPrefix + str + this.translatedMarkerSuffix;
    } else {
      return str;
    }
  }
  broadcastUpdated() {
    this.onLanguageChange.next(this.currentLanguage);
  }
  prefixDebug(str) {
    if (this.debug && this.currentLanguage !== this.baseLanguage) {
      return this.debugPrefix + str;
    } else {
      return str;
    }
  }
  /**
   * @param language
   * @param str the text to translate
   * @param n represents plural form
   * @param context
   * @private
   */
  getStringFormFor(language, str, n, context) {
    if (!language) {
      return null;
    }
    const stringTable = this.languageStrings[language] || {};
    const contexts = stringTable[str] || {};
    const plurals = contexts[context || this.noContext] || [];
    return plurals[this.gettextPluralsService.getPlurals(language, n)];
  }
}