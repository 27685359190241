import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule as AngularFormModule } from '@angular/forms';
import {
    FormsBuilderModule,
    LayoutModule,
    IconsModule,
    DialogModule,
} from '@monsido/angular-shared-components';
import { FormBackendAdminCustomerOverrideComponent } from './override.component';
import { PipesModule } from '@monsido/core/pipes/pipes.module';
import { TranslateModule } from 'app/modules/translate.module';

@NgModule({
    imports: [CommonModule, AngularFormModule, IconsModule, LayoutModule, TranslateModule, FormsBuilderModule, PipesModule, DialogModule],
    exports: [FormBackendAdminCustomerOverrideComponent],
    declarations: [FormBackendAdminCustomerOverrideComponent],
})
export class OverrideModule {}
