import { Component, Input } from '@angular/core';
import { ControlContainer, FormsModule as AngularFormModule, NgForm } from '@angular/forms';
import { CustomerInterface } from '@monsido/modules/models/api/interfaces/customer.interface';
import { CommonModule } from '@angular/common';
import {
    FormsBuilderModule,
    IconsModule,
} from '@monsido/angular-shared-components';
import { VerticalsOptionsType } from '@monsido/pages/backend-admin/verticals/verticals.type';
import { TranslateModule } from 'app/modules/translate.module';

@Component({
    selector: 'mon-form-backend-admin-customer-detail',
    templateUrl: 'detail.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: true,
    imports: [
        CommonModule,
        AngularFormModule,
        TranslateModule,
        FormsBuilderModule,
        IconsModule,
    ],
})
export class FormBackendAdminCustomerDetailComponent {
    @Input() customer: CustomerInterface;
    @Input() verticalsOptions: VerticalsOptionsType;
}
