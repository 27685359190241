import { Injectable } from '@angular/core';
import { OauthService } from '@monsido/oauth/oauth.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ActiveRegionService {
    constructor (private oauthService: OauthService) {}

    isInRegion (region: string): boolean {
        const { env } = environment;
        if (['staging', 'development', 'test'].indexOf(env) > -1) {
            return true;
        }
        return this.getRegion() === region.toUpperCase();
    }

    private getRegion (): string {
        const apiPath = this.oauthService.getApiPath();
        const splitUrl = apiPath.split('.');
        return splitUrl[1].toUpperCase();
    }
}
