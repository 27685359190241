/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorHandler, Injectable, Inject } from '@angular/core';
import Rollbar from 'rollbar';
import { Configuration } from 'rollbar';
import { InjectionToken } from '@angular/core';

export const ROLLBAR_CONFIGURATION = new InjectionToken<Configuration>('Rollbar Configuration');
@Injectable()
export class RollbarService implements ErrorHandler {
    private rollbar: any;

    constructor (@Inject(ROLLBAR_CONFIGURATION) options: Configuration) {
        this.rollbar = new Rollbar(options);
    }

    public configure (options: Configuration): Configuration {
        return this.rollbar.configure(options);
    }

    public handleError (err: any): void {
        this.rollbar.error(err.originalError || err);
    }

    public log (message: string, error?: Error, data?: Record<string, unknown>, callback?: () => void): Promise<any> {
        return this.rollbar.log(message, error, data, callback);
    }

    public info (message: string, error?: Error, data?: Record<string, unknown>, callback?: () => void): Promise<any> {
        return this.rollbar.info(message, error, data, callback);
    }

    public warn (message: string, error?: Error, data?: Record<string, unknown>, callback?: () => void): Promise<any> {
        return this.rollbar.warn(message, error, data, callback);
    }

    public error (message: string, error?: Error, data?: Record<string, unknown>, callback?: () => void): Promise<any> {
        return this.rollbar.error(message, error, data, callback);
    }

    public critical (message: string, error?: Error, data?: Record<string, unknown>, callback?: () => void): Promise<any> {
        return this.rollbar.critical(message, error, data, callback);
    }
}
