import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActiveDialog } from '@monsido/angular-shared-components';
import { BackendDomainsRepo } from '@monsido/modules/endpoints/api/backend_admin/backend-domains.repo';
import { Domain } from '@monsido/modules/models/api/domain';
import { Job } from '@monsido/modules/models/api/job';
import { BaseForm } from '@monsido/forms/base-form';

@Component({
    selector: 'mon-form-domain-rewrite-url',
    templateUrl: 'rewrite-url.html',
})
export class FormDomainURLRewriteComponent extends BaseForm implements AfterViewInit {
    @Input() domain: Domain = new Domain();
    @ViewChild('urlForm', { static: false }) urlForm: NgForm;
    onSave: boolean = false;
    fromUrl: string = '';
    toUrl: string = '';

    constructor (private activeDialog: ActiveDialog, private backendDomainsRepo: BackendDomainsRepo) {
        super();
    }

    ngAfterViewInit (): void {
        if (this.urlForm) {
            setTimeout(() => this.resetFormState(this.urlForm));
            this.activeDialog.setForm(this.urlForm);
        }
    }

    submit (): void {
        this.onSave = true;
        const params = {
            customerId: this.domain.customer_id,
            domainId: this.domain.id,
            from_url: this.fromUrl,
            to_url: this.toUrl,
        };
        this.backendDomainsRepo.rewriteUrl(params).then(
            (job) => {
                this.resetFormState(this.urlForm);
                this.close(job as unknown as Job);
            },
            () => {
                this.onSave = false;
            },
        );
    }

    // PROTECTED
    close (job: Job): void {
        this.activeDialog.close(job);
    }
}
